import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import VIDEO from 'assets/welcome-video.mp4'
import VIDEO_MOBILE from 'assets/m_welcome-video.mp4'
import { Button } from '@mui/material';
import {useTheme} from "@mui/material/styles"
import {useMediaQuery} from '@mui/material';
const style = {
    position: 'absolute',
    display:'block',
    top: {xs: '0%',sm:'50%'},
    left:{xs:'0',sm:'50%'},
    transform:{xs: '',sm:'translate(-50%, -50%)'} ,
    maxWidth:{xs:'100vh',} ,
    width:{xs:"100vw",sm:'95%'},
    height:{xs:"max-content",sm:'65%'},
    margin:'auto',
    bgcolor: 'primary.light',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    // minHeight:800,
    borderRadius: 3,
    marginBottom: 50,
    paddingBottom:10,
    transition:'1s ease-in-out'
};


const WelcomeModal=({handleClose})=>{
    const [open,setOpen]=React.useState( VIDEO?true:false)
    const theme=useTheme()
    const isMobile=useMediaQuery(theme.breakpoints.down('md'))
    React.useEffect(()=>{
        if(!open)setOpen(true);
    },[open])

    const handleCloseLocal=()=>{handleClose(false)}
    const joinBeta=()=>window.location.href='/register';
    return(
        <Modal
            keepMounted
            open={open}
            onClose={handleCloseLocal}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            // style={{overflowY:'scroll'}}
        >
            <Box sx={style}>
            <Box sx={{px:2,mt:1}}>
                <Box sx={{width:'100%',my:2}} component={'video'} src={isMobile?VIDEO_MOBILE:VIDEO} playsInline loop autoPlay muted />
            </Box>
            <Box sx={{ paddingTop: 3,display:'block',width:'100%',textAlign:'center',position:'absolute',bottom:20 }}>
                    <Button  onClick={handleCloseLocal} sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px',px:5,textTransform:'capitalize'}} variant="outlined">Close</Button>
                    {window.location.pathname == '/' ?
                        <Button onClick={joinBeta} sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px', px: 5, marginLeft: 10, textTransform: 'capitalize' }} variant="outlined">Join Beta</Button> :
                        null
                    } 
           
            </Box>
            </Box>
        </Modal>
    )
}

export default WelcomeModal;