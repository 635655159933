import React from 'react';
import axios from 'axios';

export const Purchase = () => {
  const handleCheckout=()=>{
    axios.post('/api/v1/payment/buy-plan',{plan_id:'646a4ff069e7070b94b2b11e'})
    .then(({data})=>{
      window.location.href=data.url;
    })
  }

  return (
    <button onClick={handleCheckout}>
      Pay with Stripe
    </button>
  );
};

export default Purchase;