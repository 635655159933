import { Edit,Delete, Check } from "@mui/icons-material"
import {Typography,Box, Button,Avatar, Dialog,DialogTitle,
DialogContent,DialogContentText,DialogActions,} from '@mui/material'
import axios from "axios"
import CustomInput from "component/Input/CustomInput"
import CustomInputDark from "component/Input/CustomInputDarkText"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { set_message } from "redux/reducer/message"
import OTPModal from "component/Modal/OTPMddal"
import PhoneNumberInputDark from "component/Input/PhoneInputDark"
import {Delete as Trash} from '@mui/icons-material'
import { deleteMyAccount } from "api"
import addEvent from "utils/addEvent"
import {Modal} from "@mui/material"
const Heading=()=>{
return(
    <Box sx={{width:'100%',marginTop:5,borderTopLeftRadius:5,borderTopRightRadius:5,paddingY:4,textAlign:'center',border:'1px solid #293143'}}>
        <Typography variant="h5" sx={{color:'#fff'}}>Account info</Typography>
    </Box>
)
}

const Row=({name,value,field,setModalData,notEditable})=>{
    const [edit,setEdit]=useState(false);
    const [text,setText]=useState(value);
    const dispatch=useDispatch()
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        axios.patch('/api/v1/user/profile',{[field]:text})
        .then((res)=>{
            res.data?.msg && dispatch(set_message(res.data.msg));
           if(res.data.otp){
            setModalData({visible:true,type:res.data.otp,username:text})
           }
        })
        .catch(err=>{
            dispatch(set_message(err?.response?.data?.msg));
            setText(value)
        })
        setEdit(false);
    }
return(
    <>
    <Box sx={{width:'100%',paddingY:4,border:'1px solid #293143',display:'flex',flexDirection:{xs:'column',md:'row'}}}>
    <Typography variant="h6" sx={{color:'#fff',width:250,textAlign:'left',paddingLeft:5}}>{name}</Typography>
    {!edit?
     <Typography variant="h6" onClick={()=>setEdit(prev=>!prev)} sx={{color:'#fff',cursor:"pointer",width:250,textAlign:'left',paddingLeft:5,textDecoration:'underline'}}>{text}</Typography>:
     <Box sx={{display:'flex',paddingLeft:5}}>
        <CustomInputDark placeHolder={name} value={text} onChange={e=>setText(e.target.value)}/>
        <Button onClick={handleSubmit} variant="text" sx={{color:'#fff'}} >
            <Check/>
        </Button>
    </Box>
     }
   
    {/* <Button onClick={()=>setEdit(prev=>!prev)} sx={{marginLeft:'100px',display:notEditable?'none':'inline-bllock'}}><Edit sx={{color:'#fff'}}/></Button> */}
</Box>
</>
)
}

const PhoneRow=({name,country_code,phone,field,setModalData})=>{
    const [edit,setEdit]=useState(false);
    const [text,setText]=useState(country_code+" "+phone);
    const dispatch=useDispatch()
    
    const handleSubmit=(e)=>{
        e.preventDefault();
        axios.patch('/api/v1/user/profile',{[field]:text})
        .then((res)=>{
            res.data?.msg && dispatch(set_message(res.data.msg));
           if(res.data.otp){
            setModalData({visible:true,type:'phone',username:text})
           }
        })
        .catch(err=>{
            dispatch(set_message(err?.response?.data?.msg));
            setText(country_code+" "+phone)
        })
        setEdit(false);
    }
return(
    <>
    <Box sx={{width:'100%',paddingY:4,border:'1px solid #293143',display:'flex',flexDirection:{xs:'column',md:'row'}}}>
    <Typography variant="h6" sx={{color:'#fff',width:250,textAlign:'left',paddingLeft:5}}>{name}</Typography>
    {!edit?
     <Typography variant="h6" onClick={()=>setEdit(prev=>!prev)} sx={{cursor:'pointer',color:'#fff',width:250,textAlign:'left',paddingLeft:5,whiteSpace:'nowrap',textDecoration:'underline'}}>{text}</Typography>:
     <Box sx={{display:'flex',paddingLeft:5}}>
        <PhoneNumberInputDark data={{country_code,phone}} setData={setText}/>
        <Button onClick={handleSubmit} variant="text" sx={{color:'#fff'}} >
            <Check/>
        </Button>
    </Box>
     }
    {/* <Button onClick={()=>setEdit(prev=>!prev)} sx={{marginLeft:'100px'}}><Edit sx={{color:'#fff'}}/></Button> */}
  </Box>
</>
)
}



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 410,
    width:'90%',
    margin:'auto',
    bgcolor: 'primary.light',
    border: '1px solid #000',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    borderRadius: 3,
    marginBottom: 50,
};




const DeletePopup = () => {
    const [showConfirm, setShowConfirm] = useState(false);
    const dispatch = useDispatch();
    const deletedata = () => {
        deleteMyAccount()
            .then(() => {
                dispatch(set_message('account deleted !!'));
                window.location.href = '/login';
            })
            .catch((err) => { dispatch(set_message('something went wrong')) })
    }

    const toggle = () => {
        setShowConfirm(!showConfirm)
    }

    return (
        <>
            <Modal
                keepMounted
                open={showConfirm}
                onClose={toggle}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
              
                   <Typography sx={{textAlign:'center'}} variant="h5" component="h2">
                       Closing your Account?
                    </Typography>
                    <Box sx={{my:5}}>
                        <Typography  sx={{textAlign:'center'}} variant="h6" component="h2">
                        Sorry to see you go!
                        </Typography>
                    </Box>
                    <Box sx={{textAlign:'right'}}>
                      <Button  sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize' }}  onClick={toggle}>Cancel</Button>
                      <Button  sx={{ml:2,color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize' }} variant="outlined" onClick={deletedata} autoFocus>
                          Close Account
                      </Button>
                    </Box>
                 </Box>
            </Modal>

            <Typography onClick={toggle} variant="h6" sx={{ cursor: 'pointer', color: '#fff', width: 250, textAlign: 'left', paddingLeft: 5, textDecoration: 'underline' }}>Close Account</Typography>
        </>
    )
}

const DeleteSection = () => {
    const [showConfirm, setShowConfirm] = useState(false);
    return (
        <>
            <Box sx={{ width: '100%', paddingY: 4, border: '1px solid #293143', display: 'flex' ,flexDirection:{xs:'column',md:'row'}}}>
                {/* <Box>
                    <Typography variant="h6" sx={{ color: '#fff', width: 250, textAlign: 'left', paddingLeft: 5 }}>Delete Account</Typography>
                    <Typography sx={{ color: '#fff', width: 250, textAlign: 'left', paddingLeft: 5 }}>This action canot be reversed. You will immediately loose access to your account. </Typography>
                </Box> */}
                <DeletePopup showConfirm={showConfirm} setShowConfirm={setShowConfirm} />
                <Typography sx={{ color: '#fff',textAlign: 'left', paddingLeft: 5 }}>This action cannot be reversed. You will immediately loose access to your account and all your info will be erased within 30 days.</Typography>
            </Box>
        </>
    )
}

export const MyAccount=()=>{
    const {user}=useSelector(state=>state.user);
    const [modalData,setModalData]=useState({visible:false,type:null,username:null})

    useEffect(()=>{
        addEvent('View My Account')
    },[])
    return (
        <>
        <OTPModal setModalData={setModalData} username={modalData.username} visible={modalData.visible} type={modalData.type}/>
        <Box sx={{maxWidth:'900px',width:'98vw',margin:'10px auto',padding:'10px',height:'max-content'}}>
            <Box sx={{display:'flex',alignItems:'center',marginTop:5}}>
                <Box>
                    <Avatar style={{color: '#fff',backgroundColor: '#293143',height:60,width:60,borderRadius:'100%',}}>
                    {user?.name?.charAt(0)}
                    {user?.name?.charAt(1)}
                    </Avatar>
                </Box>
                <Box sx={{marginLeft:'40px'}}>
                    <Typography sx={{color:'#fff'}} variant="h4">{user?.name} </Typography>
                    <Typography  sx={{color:'#fff'}}>{user?.email}</Typography>
                </Box>
            </Box>
            <Box sx={{mt:5}}></Box>
            {/* <Heading/> */}
            <Row name='Email' setModalData={setModalData} value={user?.email} field={'email'}/>
            <PhoneRow name='Phone' setModalData={setModalData} country_code={user.country_code} phone={user.phone} field={'phone'}/>
            <DeleteSection/>
        </Box>
        </>
    )
}


export default MyAccount;