import {  logEvent ,getAnalytics} from "firebase/analytics";
import { initializeApp } from "firebase/app";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBGAcMsRG2IbeEVmgGiuDNkNjz4CsihXhM",
    authDomain: "xgain-44f90.firebaseapp.com",
    projectId: "xgain-44f90",
    storageBucket: "xgain-44f90.appspot.com",
    messagingSenderId: "750604493402",
    appId: "1:750604493402:web:67cdb5139ad611f4462e63",
    measurementId: "G-7FXDKF6R21"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);


const addEvent=(name='None',data)=>{
    if(window.origin=='https://xgain.ai'){
      logEvent(analytics, name, data);
    }  
}
export default addEvent;