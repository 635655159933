import { Typography } from "@mui/material"
const FormateDelta = (value) => {
    return {
        element: 
            <Typography fontWeight='bold' sx={{ margin: 0, padding: 0, width: '100%'}}>
                {((value - 1) * 100).toFixed(1) + '%'}
            </Typography>
        ,
        sx:{background:((value - 1) * 100) < 0 ? 'rgb(187, 68, 68)' : 'rgb(36, 136, 34)',height:'max-content' }
    }
}

export default FormateDelta;