import { useSelector } from "react-redux";

const ExchangeIcon=({name,size,isLink=true})=>{
    const data=useSelector(state=>state.crypto.exchanges)
    return(
        <div style={{margin:5}}>
            {isLink?( <a title={name} href={data[name]?.link} target='_blank'>
            <img height={size||40} alt={name} src={data[name]?.img}/>
            </a>):(
                <img height={size||40} alt={name} src={data[name]?.img}/> 
            )}
        </div>
    )
}

export default ExchangeIcon;