import axios from 'axios'
import { CategoryChartLayout } from 'layout'
import { CoinTable } from 'component/Table'
import { TableCoinData } from 'data'
import { Box } from '@mui/material'
import BottomTab from 'component/BottomTab'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { getCategoty } from 'api/crypto'
import CategoryTable from 'layout/dashboard/category/category-table-layout'
import addEvent from 'utils/addEvent'

export const CryptoCategory = () => {
    const [crypto, setCrypto] = useState([]);
    const [total, setTotal] = useState({ total_invested: 0, total_sell: 0 });
    let { portfolio, category } = useParams();
    

    
    useEffect(() => {
        fetchData();
        addEvent('View Category',{portfolio,category})
    }, [portfolio, category])

    const fetchData = useCallback(async () => {
        getCategoty(portfolio, category)
            .then(res => {
                setCrypto(res.data?.data?.[0]?.assets||[])
                res.data.total_data[0]&&setTotal(res.data.total_data[0])
            })
    }, [portfolio,category])
    return (
    <Box sx={{pb:8}}>
        <CategoryChartLayout total_invested={total.total_invested} total_sell={total.total_sell} crypto={crypto} category_name={category} />
        <CategoryTable key={Math.random()} refresh={fetchData} data={crypto} />
        <BottomTab />
    </Box>)
}
