import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user:null,
  token:null,
  loaded:false,
  plan:null,
  p_and_l:0,
  unread_notification:0,
}

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    set_user: (state,action) => {
      state.loaded=true;
        state.user=action.payload;
    },
    set_plan:(state,action) => {
      state.plan=action.payload;
    },
    set_token: (state,action) => {
      state.token=action.payload;
    },
    set_p_and_l:(state,action)=>{
      state.p_and_l=action.payload;
    },
    set_unread_notification:(state,action)=>{
      state.unread_notification=action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { set_token,set_user,set_plan,set_p_and_l,set_unread_notification } = userSlice.actions

export default userSlice.reducer