function FormatToIntQty(labelValue){
        // Nine Zeroes for Billions
        return (labelValue<0?"- ":"")+( Math.abs(Number(labelValue)) >= 1.0e+9
    
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6
    
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3
    
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"
    
        : Math.abs(Number(labelValue)).toFixed(2));
    
    
}

export default FormatToIntQty;