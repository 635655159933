const ColumnData={
    rank:true,
    symbol:true,
    qty:true,
    price:true,
    avg_buy:true,
    invested:true,
    xgain:true,
    worth:true,
    p_and_l:true,
    pXgain:true,
    pRoI:true,
    xATH:true,
    deltaATH:true,
    deltaHr:true,
    deltaDay:true,
    deltaWeek:true,
    deltaMonth:true,
    deltaYear:true,
    Hr24Vol:true,
    mktCap:true,
    trade:true,
    action:true,
    }
export default ColumnData;