import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
const PrivacyPolicyText = () => {
  return (
    <Typography
      my={2}
      variant="subtitle1"
      textAlign="center"
      lineHeight={"15px"}
      fontSize={14}
    >
      By using this site, you are agreeing to
      <Link to='/term-of-service' style={{ color: "#1976d2", marginRight: "5px",textDecoration:'none' }}>
        {" "}
        Terms & Conditions
      </Link>
      and <Link to='/privacy' style={{ color: "#1976d2", marginRight: "5px",textDecoration:'none'  }}>
        Privacy Policy
      </Link>
    </Typography>
  );
};

export default PrivacyPolicyText;
