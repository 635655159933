import { Table, TableBody, TableCell, TableHead, TableRow, Typography,Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormateCurrency from "utils/FormateCurrency";
// import FormateText from "../../utils/FormateText";
import FormateText from "utils/FormateText";
import addEvent from "utils/addEvent";

const Text=({children,sx})=>(
    <Typography sx={{display:'inline',fontWeight:'bold',ml:1,...sx}}>
        {children}
    </Typography>
)


const CommonDetail=({data})=>{
    let labels=[
        <Text sx={{fontWeight:"100"}}>Qty</Text>,
        <Text sx={{fontWeight:"100"}}>AveBuy(Old)</Text>,
        <Text sx={{fontWeight:"100"}}>ATH</Text>,
        <Text sx={{fontWeight:"100"}}>SwingLow</Text>,
        <Text sx={{fontWeight:"100"}}>Category</Text>
    ]
    let values=[
        <Text>{data.acitivity_data?.qty}</Text>,
        <Text>{FormateCurrency(parseFloat(data?.acitivity_data?.avg_buy))}</Text>,
        <Text>{FormateCurrency(parseFloat(data.acitivity_data?.ATH))}</Text>,
        <Text>{FormateCurrency(parseFloat(data.acitivity_data?.ATL))}</Text>,
        <Text>{data.acitivity_data?.category}</Text>,
    ]
    return values.map((_,index)=><>{labels[index]} {values[index]} </>)
}
const getDetail=(data)=>{
    switch(data.activity_type){
        case "ADD MORE COIN":{
        let labels=[
            <Text sx={{fontWeight:"100"}}>Added Qty</Text>,
            <Text sx={{fontWeight:"100"}}>OLD Qty</Text>,
            <Text sx={{fontWeight:"100"}}>Purchase Price</Text>,
            <Text sx={{fontWeight:"100"}}>NewAveBuy</Text>,
            <Text sx={{fontWeight:"100"}}>OldAveBuy</Text>,
            <Text sx={{fontWeight:"100"}}>Category</Text>
        ]
        let values=[
            <Text>{data.acitivity_data.qty}</Text>,
            <Text>{data.acitivity_data.old_qty}</Text>,
            <Text>{FormateCurrency(parseFloat(data.acitivity_data.price||0))}</Text>,
            <Text>{FormateCurrency(parseFloat(data.acitivity_data.new_avg_buy||0))}</Text>,
            <Text>{FormateCurrency(parseFloat(data?.acitivity_data?.old_avg_buy||0))}</Text>,
            <Text>{data.acitivity_data.category}</Text>,

        ]
        return values.map((_,index)=><>{labels[index]} {values[index]} </>)
        

    }
        case "ADD NEW COIN":
            return <CommonDetail data={data}/>
        
        case "DELETE_COIN":{
            return <CommonDetail data={data}/>
        }
        case "EDIT COIN":{
            return <CommonDetail data={data}/>
        }

        case "EXPORT_EXCEL":
            return null

        case "UPDATED_SELL":
            let labels=[
                <Text  sx={{fontWeight:"100"}}>XGain</Text>,
                <Text  sx={{fontWeight:"100"}}>Grand Total</Text>,
                <Text  sx={{fontWeight:"100"}}>Profit</Text>,
            ]
            let values=[
                <Text>{data.acitivity_data.xgain}</Text>,
                <Text>{FormateCurrency(parseFloat(data.acitivity_data.grand_total_sell||0))}</Text>,
                <Text>{FormateCurrency(parseFloat(data?.acitivity_data?.total_profit_sell||0))}</Text> ,    
            ]
            return values.map((_,index)=><>{labels[index]} {values[index]} </>)
            
        default:
            return <Text>No Detai's</Text> 

    }
}


export const ActivityTable = ({data=[]}) => {
   
    return (
        <Box sx={{height:'100%' ,maxHeight:900,overflow:'scroll'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">#</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">When</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Activity</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Details</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {data.map((element,index)=>
                (
                    <TableRow style={{borderColor:'none'}} >
                        <TableCell sx={{color:"#fff"}}>{index+1}</TableCell>
                        <TableCell sx={{color:"#fff"}}>{new Date(element.createdAt).toLocaleString()}</TableCell>
                        <TableCell sx={{color:"#fff"}}> {FormateText(element.activity_type)} </TableCell>
                        <TableCell sx={{color:"#fff"}}>
                            {getDetail(element)}
                        </TableCell>
                    </TableRow>
                )
              )}
            </TableBody>
        </Table>
        </Box>
    )
}

export default ActivityTable;