import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import img_tab from 'assets/img_tab.png'
import VIDEO from 'assets/howto.mp4'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 410,
    width:'90%',
    margin:'auto',
    bgcolor: 'primary.light',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    borderRadius: 3,
    marginBottom: 50,
};

export default function NoCoin({show,setShow}) {
    const [open,setOpen]=React.useState(false)
    const handleClose=()=>{
        if(setShow){
            setShow(false);
        }
        setOpen(false)
    }
    React.useEffect(()=>{
        if(show){
            return setOpen(true)
        }
        let prev=localStorage.getItem("no-coin");
        if(!prev || prev!==new Date().toLocaleDateString()){
            setOpen(true);
            localStorage.setItem('no-coin',new Date().toLocaleDateString())
        }
    },[])
    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box component='form'  sx={style}>
                <Box sx={{ marginBottom: 1, textAlign: 'center' }}>
                      <Box sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        {/* <Typography sx={{color:'#fff',}} variant='h5'>WELCOME</Typography><br/><br/> */}
                        <Typography sx={{color:'#fff',mt:1.5}} variant='h5'>How To Use</Typography>
                     </Box>
                </Box>
                <Box>
                    <Box sx={{width:'100%',my:1}} component={'video'} src={VIDEO} playsInline loop autoPlay muted />
                </Box>
                <Box sx={{px:2,mt:4}}>
                   {/* <Typography variant='body'>1. Add Coins</Typography><br/><br/>
                   <Typography variant='body'>2. Select coin on Assets</Typography><br/><br/>
                   <Typography variant='body'>3. Slide 4 Exit points on the Fibonacci Chart</Typography><br/><br/><br/>
               
                   <Typography variant='body'>Send feedback for us to improve your experience.</Typography><br/><br/> */}
                </Box>
                <Box sx={{ width: '100%', height: 1.1, background: '#fff', marginTop: 3 }} />
                <Box sx={{ paddingTop: 3,display:'block',textAlign:'center' }}>
                    <Button  onClick={handleClose} sx={{ color: '#fff', px:4,borderColor: "#fff", borderRadius: '20px' }} variant="outlined">OK</Button>
                </Box>
            </Box>
        </Modal>
    );
}


// sx={{border:"1px solid #fff"}} labelStyle={{background:'black',padding:'0px 5px'}} style={{color:'#fff'}} 