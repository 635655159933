import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch,useSelector } from "react-redux";
import axios from "axios";
import Navbar from '../component/NavbarDashboard';
import Footer from "../component/Footer";
import { set_plan, set_user } from "redux/reducer/user";
import NoCoin from "component/Modal/NoCoin";
import PayNow from "pages/private/pay-now";
import { set_category } from "redux/reducer/crypto";
import { getAllCategory } from "api/crypto";

export const DashboardLayout = () => {
    const { plan,loaded } = useSelector(state => state.user);
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const location=useLocation();
    const {crypto,cryptoLoaded} = useSelector(state=>state.crypto)

    useEffect(()=>{
        window.scrollTo(0,0)
    },[location])
   // code for persistence login
    useEffect(() => {
        axios.get('/api/v1/user/profile')
            .then(res => {
                dispatch(set_user(res.data.user))
                dispatch(set_plan(res.data.plan))
                if(!window.location.pathname.includes('/dashboard')){
                    navigate('/dashboard/main');
                }
            })
            .catch((err) => {
                console.log(err)
                if(err.response.status==401){
                    navigate('/login');  
                }
                dispatch(set_user(null))
            })
       getAllCategory()
        .then(res=>{
            dispatch(set_category(res.data.category))
        }).catch(err=>console.log(err))
    }, [])

    // useEffect(() => {
    //     if (!user && loaded) {
    //         navigate('/login')
    //     }
    // }, [user, loaded, navigate])

    return (
        <>
            <Navbar />
            <Box sx={{  bgcolor: 'primary.main', minHeight: 'calc(100vh - 55px)',overflowY:'scroll' }}>
                {!loaded ? <Box sx={{ minHeight: "90vh" }}>
                    <CircularProgress sx={{ color: '#fff' }} />
                </Box> :
                  
                   <>
                    {plan?.name?<Outlet />:<PayNow/>}
                    {(crypto && !crypto.length && cryptoLoaded) &&  <NoCoin/>}
                    </>
                }
            
            </Box>
        </>
    )
}

export default DashboardLayout;