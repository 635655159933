import { Box } from "@mui/system"
import {useTheme} from "@mui/material/styles"
import CategoryButton from "component/Buttons/CategoryButton"
import AddCoinButton from "component/Buttons/AddCoinButton"
import CryptoRow from "component/CryptoRow"
import BottomTab from "component/BottomTab"
import AddCrypto from "component/Modal/AddNewCrypto"
import { useState,useEffect,useMemo ,useCallback} from "react"
import axios from "axios"
import { getPortfolio } from "api/crypto"
import { Typography, useMediaQuery } from "@mui/material"
import { useSelector } from "react-redux"
import { AssetsDetail } from ".."
import addEvent from "utils/addEvent"
export const Assets = () => {
    const theme=useTheme()
    const isMobile=useMediaQuery(theme.breakpoints.down('md'))
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState({ allData: [], category: 'All' });
    const [selected,setSelected]=useState(null);
    const { plan } = useSelector(state => state.user);
    const category=useSelector(state=>state.crypto.category)

    useEffect(() => {
        fetchAll();
        let timer = setInterval(() => {
           fetchAll();
        }, 120000)
        addEvent('Assets Page')
        return () => { clearInterval(timer); }
    }, []);

    const filterData = useMemo(() => {
        return data.category=='All'?data.allData: data.allData.filter(obj => obj.categoryId == data.category);
    }, [data.allData, data.category])

    const fetchAll = useCallback(() => {
       getPortfolio()
            .then(res => {
                setData((prev) => ({ ...prev, allData: res.data.data[0]?.assets||[] }));
                if(selected){
                    setSelected(res.data.data[0]?.assets.find(obj=>obj.symbol==selected.symbol))
                }
            })
    }, [data.category,selected])
    
    const changeCategory = (id) => {
        setData({ ...data, category: id })
    }
    const toggle = () => setVisible(prev => !prev)
    return (
        <Box sx={{display:'flex',height:'calc(100vh - 60px)'}}>
        <Box sx={{width:{xs:'100%',md:'45%',lg:'20%',minWidth:360},overflowY:'scroll',maxHeight:'100%',}}>
           {plan?.name=='Pro' &&
           <Box sx={{ my:2}}>
           <CategoryButton selected={data.category=='All'} onClick={() => {changeCategory('All') }}   text='All' />
           {category.map(({name,_id})=>{
            return <CategoryButton selected={data.category==_id} onClick={() =>changeCategory(_id)}   text={name} />
           })}
           
           {/* <CategoryButton selected={data.category=='BigCap'} onClick={() => {changeCategory('BigCap') }} text='BigCap' />
           <CategoryButton selected={data.category=='De-Fi'} onClick={() => changeCategory('De-Fi')} text='DeFi' />
           <CategoryButton selected={data.category=='NFT'} onClick={() => {changeCategory('NFT') }} text='MetaVerse' />
           <CategoryButton selected={data.category=='Metaverse'} onClick={() => {changeCategory('Metaverse')}} text='Gaming' />
           <CategoryButton selected={data.category=='LowCap'} onClick={() => {changeCategory('LowCap')}} text='HighX' /> */}
           <AddCrypto refresh={fetchAll} visible={visible} setVisble={setVisible} />
       </Box>
           } 
            
            <Box sx={{paddingBottom:10,minHeight:'calc(100vh - 80px)'}}>
                {filterData.length==0 && <Box sx={{marginBottom:-10,width:'100%',height:'calc(100vh)',borderRight:'1px solid grey',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Typography sx={{color:'#fff'}}>No coin available</Typography>
                    </Box>}
                {filterData.map((obj)=> <CryptoRow selected={selected?.symbol==obj.symbol} data={obj} setSelected={setSelected} {...obj}/>)}
            </Box>
            <BottomTab />
        </Box>
        {!isMobile &&
         <Box sx={{width:'100%',overflowY:'scroll',maxHeight:'calc(100vh - 80px)'}}>
         {selected ?<AssetsDetail  fetchAll={fetchAll}  selected={selected}/>:(!filterData?.length==0&& <Box sx={{width:'100%',height:'100%',display:'grid',placeContent:'center'}}>
            <Typography sx={{color:'#3B4C77', fontStyle:'black',fontSize:70}}>Select a Coin</Typography>
         </Box>) }
         </Box>
        }

        </Box>
    )
}


