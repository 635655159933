// import { Box,Typography } from "@mui/material"
// import Section from "../../components/Section"
// import PortfolioSS from 'assets/xcxg-main.png'
// import RedOutlineButton from "../../components/RedOutlineButton"
// import BigX from 'assets/bigx.png'
// import LogoImg from 'assets/xgainTM.png'
// import { useEffect, useState } from "react"

// const PortfolioSection=()=>{
//   const [data,setData]=useState({title:"",description:""});
//   // useEffect(()=>{
//   //   fetch('/data/screen-1.json')
//   //   .then(res=>res.json())
//   //   .then(setData)
//   // },[])

//     return(<Section sx={{bgcolor:'black',borderBottom:'1px solid #fff',display:{xs:'block',md:'flex'},overflow:'hidden',position:'relative'}}>
    
//     <Box sx={{
//       zIndex:99,
//       position:'relative',
//       width:'90%',
//       margin:'auto',
//       maxWidth:'1600px',
//       marginTop:10,
//       textAlign:'center',
//       }}>
//       <Typography  sx={{textAlign:'center'}}>
//       <img height={50} src={LogoImg} alt='logo' />
//       </Typography>
//       <Typography  sx={{color:'#fff',fontSize:25,fontWeight:'bold',textAlign:'center'}}>{data.title}</Typography>
//       <Box
//          sx={{width:'98%',display:'block',margin:'auto',marginTop:5,maxWidth:'900px'}}
//          component={'img'}
//          src={PortfolioSS}
//         />
//       <Typography  sx={{color:'#fff',marginTop:{xs:5,md:1,},textAlign:{xs:'center',md:'left'},display:{xs:'block',md:'inline-block'}}}>
//      {data.description}
//       </Typography>
//       <Box sx={{display:'flex',justifyContent:{xs:'center'}}}>
//       <RedOutlineButton sx={{marginTop:10,}}/>
//       </Box>
//     </Box>
//     <Box 
//     sx={{
//         position:'absolute',
//         zIndex:0,
//         maxWidth:500,
//         left:0,
//         right:0,
//         margin:'auto',
//         bottom:{xs:"-26%",md:'-25%'},
//     }}
//     component={'img'} 
//     src={BigX}
//     />
//     </Section>)
// }

// export default PortfolioSection;




import { Button, Typography, Box } from "@mui/material";
import RedOutlineButton from "../../components/RedOutlineButton";
import Section from "../../components/Section";
import BigX from 'assets/bigx.png'
import LogoImg from 'assets/xgainTM.png'
import CheckCircleOutlineIcon from '@mui/icons-material/DoneAll';

const FeatureItem = ({text}) => (
  <Box sx={{display:'flex',alignItems:'center',mt:1}}>
    <CheckCircleOutlineIcon sx={{ marginRight: '8px', color: '#fff', }} />
    <Typography sx={{ color: '#fff', }}>{text}</Typography>
  </Box>
)


const Portfolio = () => {
  return (<Section sx={{ bgcolor: 'black', borderBottom: '1px solid #fff', paddingtTop: 10 }}>
    <Box sx={{
      px: { xs: 8, md: 0 },
      zIndex: 1,
      position: 'relative',
      maxWidth: {
        md: '50%',
        lg: '40%'
      },
      marginLeft: { md: 20 },
      marginTop: {
        sm: '10%',
        md:'3%'
      }
    }}>
      <Typography sx={{ color: '#fff', fontSize: 50, fontFamily: 'ethnocentric' }}>
        <img height={50} src={LogoImg} alt='logo' />
      </Typography>
      <Typography sx={{ color: '#fff', fontSize: 25, fontWeight: 'bold' }}>Embark on Your Crypto Odyssey!</Typography>
      <Typography sx={{ color: '#fff', fontSize: 16,  }}> Steer confidently through cryptocurrency's dynamic landscapes with XGains’ revolutionary Crypto Category Dashboards.

It’s a strategy, analysis, and optimization powerhouse for all your assets. Immerse yourself in a user-friendly interface brimming with live data, sharp analytics, and key insights to sculpt your investment strategies to ascend to new peaks in the ever-evolving world of crypto!</Typography>
      
      <Typography sx={{ color: '#fff', marginTop: 3 }}>
       
          <FeatureItem text={<>Category Level Dashboard & <span style={{ color: 'red', fontWeight: 'bold' }}>X</span>Gain</>}/>
          <FeatureItem text={<>Investment vs Live Worth Chart</>}/>
          <FeatureItem text={<>Crypto Level Dashboards & <span style={{ color: 'red', fontWeight: 'bold' }}>X</span>Gains</>}/>
          <FeatureItem text={'Live Crypto performance breakdown'}/>
          <FeatureItem text={'Live Fibonacci Charts for top 1000 Crypto'}/>
          <FeatureItem text={'DCA in & Out (Exit) Strategies on Live Fibonacci'}/>
          <FeatureItem text={'Live 4 Year Bitcoin Cycle Tracker'}/>
          <FeatureItem text={'Links to your Exchanges & Wallets'}/>
          <FeatureItem text={<>Price Discovery <span style={{ color: 'red', fontWeight: 'bold' }}>X</span>Gains</>}/>
          <FeatureItem text={<>Portfolio, Category & Asset <span style={{ color: 'red', fontWeight: 'bold' }}> X</span>Gains</>}/>
      </Typography>
      <RedOutlineButton sx={{ marginTop: 3 }} /><br /><br />

      {/* <Typography  sx={{color:'#fff',fontSize:'1.2rem',fontWeight:'bold'}}>Download 7 Lethal Traps Crypto Investors Falling To!</Typography> */}
    </Box>
    <Box component={'img'}
      sx={{
        width: {
          md: '50%',
          xs: '80%',
        },
        position: 'absolute',
        top: '18%',
        right: '5%',
        zIndex: 0,
        opacity: {
          xs: 0.4,
          md: 1,
        }
      }}
      src={BigX}
      alt="Large letter x in red design" />
  </Section>)
}

export default Portfolio;