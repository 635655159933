import * as React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import CustomButton from "../../../component/Buttons/CustomButton";
import PrivacyPolicyText from "../../../component/PrivacyPolicyText";
import {resendCode, sendCode, verifyCode} from 'api';
import { set_message } from "redux/reducer/message";
import axios from "axios";
import { set_user,set_plan } from "redux/reducer/user";
import Section from "../home/components/Section";
export const OTP = () => {
    const [otp,setOtp]=React.useState("");
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [loading,setLoading]=React.useState(false);
    const [counter,setCount]=React.useState(0);
    const {state}=useLocation();
    const ALPHA_NUMERIC_DASH_REGEX = /^[0-9-]+$/;
    const username=state?.username;
    const ref1=React.useRef();
    const ref2=React.useRef();
    const ref3=React.useRef();
    const ref4=React.useRef();
    const ref5=React.useRef();
    const ref6=React.useRef();
    const refArry=[ref1,ref2,ref3,ref4,ref5,ref6];
    const [error,setError]=React.useState(null);
    React.useEffect(()=>{
      if(!username)navigate("/login");
      ref1.current.focus();
      localStorage.removeItem('user-welcome')
    },[username,navigate])
  
   const startTimer= React.useCallback(()=>{
      setCount(30);
      let interval=setInterval(()=>{
        setCount(prev=>{
          if(prev==0){
            return clearInterval(interval);
          }
          return prev-1;
        })
      },1000)
    })

    const handleChange=(e)=>{
        if(e.key==="Backspace"){
            if(e.target.name==="1"){
                setOtp("")
                return
            }
            if(otp[e.target.name-1]!==undefined){
              setOtp(prev=>prev.slice(0,-1))
            }
            refArry[e.target.name-2].current.focus();
        }else{
          
            if(e.target.name==="6"){
                if(otp[5]){
              
                    let new_otp=otp.slice(0,-1)+e.key.replace(otp[5],"")
                    setOtp(new_otp)
                }else{
                    setOtp(prev=>prev+e.key);
                }
               
            }else{
                setOtp(prev=>prev+ e.key.replace(otp[e.target.name-1],""));
                refArry[parseInt(e.target.name)].current.focus();
            }            
        }
    }
    const handleVerify=React.useCallback(()=>{
      setLoading(true)
      verifyCode(username,otp)
      .then(()=>{
            axios.get('/api/v1/user/profile')
            .then(res => {
                dispatch(set_user(res?.data?.token))
                dispatch(set_plan(res?.data?.plan))
                setTimeout(()=>{
                  navigate('/dashboard/main');
                },200)
               
            })
            .catch(() => {
                dispatch(set_user(null))
            })
       

      })
      .catch(err=>{
        if(err.response.status<500 && err.response.status>=400){
          setError(err.response.data.msg)
        }else{
          setError('something went wrong !')
        }
        setLoading(false)
      })
    },[username,otp,setLoading,dispatch,navigate])
   
    React.useEffect(()=>{
      if(otp.length===6){
        handleVerify();
      }
    },[otp,handleVerify])

    const handleKeypress=event=>{
      if (event.key!=='Backspace' && !ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
        event.preventDefault();
      }else{
        handleChange(event)
      }
    }


    const resend_code=()=>{
      if(counter)return;
      setOtp('');
      setError(null)
      ref1.current.focus();
      resendCode(username)
      .then(()=>{
        startTimer();
        dispatch(set_message('CODE sent to '+username))
      })
      .catch((err)=>dispatch(set_message(err.response.data.msg)))
    }
   
  return (
    <Section>
    <Box
      component="form"
      sx={{
        maxWidth: 400,
        width: "92%",
      }}
      mx="auto"
      autoComplete="off"
    >
      <Box
        display="flex"
        sx={{ height: "15vh", justifyContent: "center", alignItems: "center", marginTop: '30%' }}
      >
        <img src={logo} height="150px" alt="logo" />
      </Box>
      <Typography sx={{textAlign:'center'}} mt={3} variant="h6">
        Enter the 6 digit CODE we sent to you
      </Typography>
      <Typography sx={{textAlign:"center",fontWeight:'400',color:'green'}} variant="h6">
        CODE Sent...
      </Typography>
      <Box>
        <Typography sx={{color:'red',textAlign:'center'}}>{error}</Typography>
      </Box>
      <Box sx={{display:'flex'}} mt={2}>
        <TextField error={error} focused={true} onKeyDown={handleKeypress}  type="number" name="1" value={otp[0]||""}  inputRef={ref1} inputProps={{inputMode: 'numeric',style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField error={error} onKeyDown={handleKeypress}  type="number" name="2" value={otp[1]||""}  inputRef={ref2} inputProps={{inputMode: 'numeric',style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField error={error} onKeyDown={handleKeypress}  type="number" name="3" value={otp[2]||""}  inputRef={ref3} inputProps={{inputMode: 'numeric',style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField error={error} onKeyDown={handleKeypress}  type="number" name="4" value={otp[3]||""}  inputRef={ref4} inputProps={{inputMode: 'numeric',style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField error={error} onKeyDown={handleKeypress}  type="number" name="5" value={otp[4]||""}  inputRef={ref5} inputProps={{inputMode: 'numeric',style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField error={error} onKeyDown={handleKeypress}  type="number" name="6" value={otp[5]||""}  inputRef={ref6} inputProps={{inputMode: 'numeric',style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
      </Box> 
      <Box my={3} textAlign="center">
        <Typography variant="p" color="grey">
          Didn't receive it? 
          {counter?<Typography>Please retry in {counter}s</Typography>:
          <Button onClick={resend_code} style={{ cursor:'pointer',marginLeft:4,color:'#1976d2',textDecoration:'none',textTransform:'none' }} >
          Resend
       </Button>
          }
          my verification code
        </Typography>
      </Box>
      {/* <CustomButton loading={loading} onClick={handleVerify} text="Verify" /> */}
      <PrivacyPolicyText />
    </Box>
    </Section>
  );
};
export default OTP;
