import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 error:null
}



export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    set_message: (state,action) => {
        state.error=action.payload
    },
  
  },
})

// Action creators are generated for each case reducer function
export const { set_message } = errorSlice.actions
export default errorSlice.reducer