import { useMemo } from "react";
import { Grid,Box, Typography } from "@mui/material"
import ColumnChart from "component/Chart/ColumnChart";
import TreeMap from "component/Chart/TreeMap";
import { InfoCard } from "component/Card";
import LogoText from "component/Logo";
import GaugeChart from "component/GaugeChart";
import { useDispatch } from "react-redux";
import { set_p_and_l } from "redux/reducer/user";
export const CategoryChartLayout = ({crypto,category_name,total_invested,total_sell}) => {
    const dispatch=useDispatch();
    const dataCollection = useMemo(() => {
        let temp = {}
        temp.total_invested = crypto.map(obj => obj.invested).reduce((a, b) => a + b, 0)
        temp.total_value = crypto.map(obj => obj.price*obj.qty).reduce((a, b) => a + b, 0)
        temp.grand_total_sell = crypto.map((obj) => obj.grand_total_sell).reduce((a, b) => a + b, 0);
        dispatch(set_p_and_l(temp.total_value-temp.total_invested))
        return temp;
    },[crypto])

    const treeMapData = useMemo(() => {
        let temp = {}
        temp.label = crypto.map((obj) => obj?.symbol?.replaceAll('_','') + `${Math.round(obj.grand_total_sell/(obj.avg_buy * obj.qty))}X` );
        temp.series = crypto.map((obj) => (obj?.price*obj?.qty) - obj?.invested);
        return temp;
      }, [crypto])
    return (
        <>
            <Grid marginTop={1} lg={12} container spacing={1}>
                
                <Grid style={{ alignItems: 'center', display: 'flex' }} item sm={6} xs={12} lg={4}>
                    <GaugeChart
                     label={category_name}
                     total_invested={dataCollection.total_invested}
                     total_roi={dataCollection.grand_total_sell-dataCollection.total_invested}
                     total_worth={dataCollection.total_value}
                     profit_loss={dataCollection.total_value-dataCollection.total_invested}
                     xgain={(dataCollection.grand_total_sell/dataCollection.total_invested).toFixed(1)}
                    />
                </Grid>

                <Grid style={{ alignItems: 'center', display: 'flex' }} item sm={6} lg={2} xs={12} >
                    <ColumnChart 
                    key={Math.random()}
                    invested={[dataCollection.total_invested,]} 
                    worth={[dataCollection.total_value]}
                    categories={['Invested','Worth','ROI']}
                    />
                </Grid>
            
                <Grid container  rowGap={1} item xs={12} lg={6} >
                    <Grid md={12} style={{ padding: 0, alignItems: 'center',justifyContent: 'center'}} sx={{display: { xs: 'none', lg: 'flex' } }}  item>
                        <TreeMap key={Math.random()} label={treeMapData.label} series={treeMapData.series} />
                    </Grid>
                </Grid>
            
            </Grid>
            {/* mobile only */}
            <Grid sx={{
                padding: 0,
                display: {
                    lg: 'none'
                }
            }}  container>
                <Grid xs={12} style={{ padding: 0, alignItems: 'center', display: 'flex', justifyContent: 'center' ,height:250}}  item>
                    <TreeMap key={Math.random()} label={treeMapData.label} series={treeMapData.series} />
                </Grid>
            </Grid>
        </>
    )
}

export default CategoryChartLayout;