import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import FormateCurrentcy from 'utils/FormateCurrency'
import {useTheme} from "@mui/material/styles"
import { useMediaQuery } from "@mui/material"
import formateQTY from 'utils/FormateQty'
import {Badge} from '@mui/material'
const CryptoRow = ({_id,setSelected,data,icon, name, xgain, price,selected,symbol,cmc_rank,grand_total_sell,invested,OG_ATL,ATL,avg_buy,qty }) => {
    const theme=useTheme()
    const isMobile=useMediaQuery(theme.breakpoints.down('md'))
    const navigate=useNavigate();
    const handleClick=()=>{
        if(isMobile){
            navigate('/dashboard/assets/'+symbol)
        }else{
            setSelected(data)
        }
       
    }

    const errorCount=()=>{
        let error=0;
        if(!data.ATL){
            error++;
        }
        if(!data.ATH){
            error++
        }
        if(data.ATH<data.ATL){
            error++
        }
        return error;

    }
    return (
        
        <Grid onClick={handleClick} sx={{ borderBottom: `1px solid ${OG_ATL?'grey':'gold'} `, padding: '5px 10px',cursor:'pointer' ,bgcolor:selected&& '#293143'}} container>
            <Grid sx={{ alignItems: 'center', display: 'flex' }} xs={2} item>
            <Badge sx={{
                    "& .MuiBadge-badge": {
                      color: "#fff",
                      backgroundColor: "red"
                    }
                  }} badgeContent={errorCount()}>
                <img height={42} src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${symbol?.toLowerCase()}.webp`} alt={name} />
            </Badge>
            </Grid>
            <Grid sx={{ color: '#fff', fontSize: '1.3rem', textAlign: 'center' }} xs={2} item>
                <Typography fontWeight='800'>
                    {symbol?.replaceAll('_','')}
                </Typography>
                <Typography sx={{ marginTop: 0.5 }} fontWeight='800'>
                    {(grand_total_sell/invested).toFixed(0)}<span style={{ color: 'red' }}>X</span>
                </Typography>
            </Grid>
            <Grid sx={{ textAlign: 'center' }} xs={4} item>
                <Typography fontWeight={'800'} sx={{ color:price<avg_buy?'#95F205':'#fff' }}>pr {FormateCurrentcy(price)}</Typography>
                <Typography fontWeight={'800'} sx={{ color: '#fff', marginTop: 0.5 }}>av {FormateCurrentcy(avg_buy)}</Typography>
            </Grid>
            <Grid sx={{textAlign:'right',}} xs={4} item>
                <Typography fontWeight={'800'} sx={{color: ((price*qty)-invested)<0?'red':'#95F205',  }}> 
                {FormateCurrentcy((price*qty)-invested)}
                </Typography>
                <Typography sx={{color:"#fff",fontSize:14}}>
                  qty  {formateQTY(qty)}
                </Typography>
            </Grid>
        </Grid>
    )
}

CryptoRow.defaultProps = {
    icon: 'https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/btc.webp',
    name: "Bitcoin",
    xgain: 20,
    price: 1000
}
export default CryptoRow;