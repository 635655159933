import { Grid } from "@mui/material"
import DonutChart from "component/Chart/DonutChart";
import ColumnChart from "component/Chart/ColumnChart";
import TreeMap from "component/Chart/TreeMap";
import { InfoCard } from "component/Card";
import { useMemo } from "react";
import GaugeChart from "component/GaugeChart";
import { set_p_and_l } from "redux/reducer/user";
import { useDispatch, useSelector } from "react-redux";

const ChartLayout = ({ crypto,p_and_l }) => {
    const dispatch=useDispatch();
    const category=useSelector(state=>state.crypto.category)
    const dataCollection = useMemo(() => {
        let temp = {}
        temp.allData = { data: crypto };
        temp.allData.total_invested = crypto.map(obj => obj.invested).reduce((a, b) => a + b, 0)
        for( let {name,_id} of category){
            temp[name] = { data: crypto.filter((obj => obj.categoryId == _id)) }
             temp[name].total_invested = temp[name].data.map(obj => obj.invested).reduce((a, b) => a + b, 0)
             temp[name].total_value = temp[name].data.map(obj => obj.price*obj.qty).reduce((a, b) => a + b, 0)
             temp[name].fib1 = temp[name].data.map(obj => obj.fib1).reduce((a, b) => a + b, 0)
             temp[name].grand_total_sell = temp[name].data.map(obj => obj.grand_total_sell).reduce((a, b) => a + b, 0)
        }
        delete temp.allData;      
        return temp;
    }, [crypto,category])
   
    const calculatedData = useMemo(() => {
        let temp = {}
        const allData = crypto;
        temp.investment = allData.map((obj) => obj.invested).reduce((a, b) => a + b, 0);
        temp.grand_total_sell = allData.map((obj) => obj.grand_total_sell).reduce((a, b) => a + b, 0);
        temp.total_profit_sell = allData.map((obj) => obj.total_profit_sell).reduce((a, b) => a + b, 0);
        temp.xGain = (temp.grand_total_sell / temp.investment).toFixed(1);
        temp.total_value = allData.map((obj) => obj.price * obj.qty).reduce((a, b) => a + b, 0);
        temp.p_and_l =temp.total_value -temp.investment; 
        dispatch(set_p_and_l(temp.p_and_l))
        document.title=`Xgain ${temp.xGain} X`
        return temp
    }, [crypto])

    return (
        <>
            <Grid marginTop={1} container spacing={1}>
                <Grid container rowGap={1} item sm={4} display={{xs:'none',sm:'flex'}}>
                    
                    <Grid md={12} style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }} sx={{ display: { xs: 'none', sm: 'flex' } }} item>
                       {/* USED FOR DESKTOP ONLY */}
                        <ColumnChart 
                         key={Math.random()}
                         categories={Object.keys(dataCollection)}
                         invested={Object.values(dataCollection).map(obj=>obj.total_invested)}
                         worth={Object.values(dataCollection).map(obj=>obj.total_value)}
                        />
                    </Grid>
                </Grid>
                <Grid style={{ alignItems: 'center', display: 'flex' }} item sm={4} xs={12} >
                    {/* <DonutChart 
                    key={Math.random()}
                    label={['BlueChip', 'BigCap', 'DeFi', 'Metaverse', 'NFT', 'LowCap']}
                    series={[
                      dataCollection.Goldv2.total_invested,
                      dataCollection.BigCap.total_invested,
                      dataCollection.DeFi.total_invested,
                      dataCollection.Metaverse.total_invested,
                      dataCollection.NFT.total_invested,
                      dataCollection.LowCap.total_invested]}
                      xGain={calculatedData.xGain}
                    /> */}
                    <GaugeChart
                     total_invested={calculatedData.investment}
                     total_roi={calculatedData.total_profit_sell}
                     total_worth={calculatedData.total_value}
                     profit_loss={calculatedData.p_and_l}
                     xgain={calculatedData.xGain}
                     trim={p_and_l}
                    />
                </Grid>
                <Grid container rowGap={1} item sm={4} xs={0} display={{xs:'none',sm:'flex'}}>
                    {/* <Grid md={6} xs={12} item>
                        <InfoCard title='Projected ROI' value={calculatedData.grand_total_sell}/>
                    </Grid>
                    <Grid md={6} xs={12} item  >
                        <InfoCard enableColor={true} title='Profit and Loss' value={calculatedData.p_and_l}/>
                    </Grid> */}
                    
                    <Grid md={12} style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }} sx={{ display: { xs: 'none', sm: 'flex' } }} item>
                        {/* USED FOR DESKTOP ONLY */}
                        <TreeMap 
                          key={Math.random()}
                          label={Object.keys(dataCollection).map((str)=>{
                              return `${str}  ${(dataCollection[str].grand_total_sell/dataCollection[str].total_invested).toFixed(1)}X`
                          })}
                          series={Object.values(dataCollection).map((obj)=>(obj.total_value - obj.total_invested))} 
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{
                padding: 0,
                display: {
                    sm: 'none'
                }
            }} marginTop={1} container>
                <Grid style={{ padding: 0, alignItems: 'center', display: 'flex', justifyContent: 'center' }} xs={12} item>
                {/* USED FOR MOBILE ONLY */}
                    <TreeMap 
                     key={Math.random()}
                     label={Object.keys(dataCollection).map((str)=>{
                         return `${str}  ${(dataCollection[str].grand_total_sell/dataCollection[str].total_invested).toFixed(1)}X`
                     })}
                     series={Object.values(dataCollection).map((obj)=>(obj.total_value - obj.total_invested))}
                    />
                </Grid>
                <Grid style={{ padding: 0, alignItems: 'center', display: 'flex', justifyContent: 'center' }} xs={12} item>
                 {/* USED FOR MOBILE ONLY */}
                <ColumnChart 
                   key={Math.random()}
                   categories={Object.keys(dataCollection)}
                   invested={Object.values(dataCollection).map(obj=>obj.total_invested)}
                   worth={Object.values(dataCollection).map(obj=>obj.total_value)}
                />
                </Grid>
            </Grid>
        </>
    )
}

export default ChartLayout;