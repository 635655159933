import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomInput from 'component/Input/CustomInputDark';
import CustomeSelect from 'component/Select';
import { addCrypto, getCryptoById, updateCrypto } from 'api/crypto';
import { useDispatch } from 'react-redux';
import { set_message } from 'redux/reducer/message';
import { useSelector } from 'react-redux';
import FormateCurrency from 'utils/FormateCurrency';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 410,
    width:'90%',
    margin:'auto',
    bgcolor: 'primary.light',
    border: '2px solid #000',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    borderRadius: 3,
    marginBottom: 50,
};





export default function EditCrypto({refresh, visible, setVisble,id,symbol }) {
    const [open, setOpen] = React.useState(visible);
    const [data, setData] = React.useState({qty:'',avg_buy:'',ATL:'',ATH:''});
    const [ogdata, setOgData] = React.useState({qty:'',avg_buy:'',ATL:'',ATH:''});
    const [key,setkey]=React.useState(0);
    const { plan } = useSelector(state => state.user);
    const categories=useSelector(state=>state.crypto.category);
    const dispatch=useDispatch();
    React.useEffect(()=>{
        getCryptoById(id)
        .then(res=>{
            setData(res.data)
            setOgData(res.data)
        })
        .catch(err=>console.log(err))
    },[])
    React.useEffect(() => {
        if(visible){
            setkey(Math.random())
        }
        setOpen(visible);
    }, [visible]);

    const handleClose = () => {
        setOpen(false);
        setVisble(false);
    }

    const handleChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleSubmit = (e) => {
        e?.preventDefault();
        let userUpdatedATL_ATH=undefined;
        if(ogdata.ATH!=data.ATH  || ogdata.ATL!=data.ATL){
            userUpdatedATL_ATH=true
        }
        updateCrypto(id,{...data,symbol,userUpdatedATL_ATH})
        .then(res=>{
            dispatch(set_message(`updated info for ${symbol}`))
            refresh();
            setVisble(false)
            setOpen(false);
        })
        .catch(err=>console.log(err))
    }

   
    return (
        <Box 
        key={key}
        >
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box component='form' onSubmit={handleSubmit} sx={style}>
                <Box sx={{ marginBottom: 4, marginTop: 2, textAlign: 'center' }}>
                <img src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${symbol.toLowerCase()}.png`}/><br/><br/>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Update {symbol?.replaceAll('_','')}
                    </Typography>
                </Box>
                {data.ATH&& <Typography sx={{color:'#fff',my:1,ml:1,fontSize:15,fontWeight:'bold'}}>ATH : {FormateCurrency(ogdata.ATH)}</Typography>} 
                {data.ATL && <Typography sx={{color:'#fff',my:1,ml:1,fontSize:15,fontWeight:'bold'}}>SwingLow : {FormateCurrency(ogdata.ATL)} </Typography>} 
                <Box>
                    {data.category&&
                    <>
                     <CustomInput  value={data.qty} onChange={handleChange}  inputMode="numeric" name='qty' sx={{ mt: 2 }} label='Quantity' />
                     <CustomInput  value={data.avg_buy} onChange={handleChange} inputMode="numeric" name='avg_buy' sx={{ mt: 2 }} label='Ave. Buy' />
                    {(!ogdata.ATH || (ogdata.ATH<=ogdata.ATL))&& <CustomInput inputMode="numeric" value={data.ATH} onChange={handleChange} name='ATH' sx={{ mt: 2 }} label='ATH' />} 
                    {(!ogdata.ATL||  (ogdata.ATH<=ogdata.ATL) ) && <CustomInput inputMode="numeric" value={data.ATL} onChange={handleChange} name='ATL' sx={{ mt: 2 }} label='ATL' />} 
                   {plan?.name=='Pro' && <CustomeSelect 
                    onChange={handleChange}
                    name='category' 
                    label='Category'
                    value={data.category}
                    customLabel={true}
                       option={categories.map(({name,_id})=>({value:name, label:name}))}
                    />} 
                    </>
                   } 
                </Box>
                <Box sx={{ width: '100%', height: 1.1, background: '#fff', marginTop: 3 }} />
                <Box sx={{ paddingTop: 3,width:'max-content',display:'block',ml:'auto'}}>
                  <Button onClick={handleClose} sx={{ color: '#fff', borderRadius: '20px', mr: 2 ,textTransform:'capitalize'}} variant='outlined'>Cancel</Button>
                  <Button type='submit' sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize' }} variant="outlined">Save</Button>
                </Box>
            </Box>
        </Modal>
        </Box>
    );
}


