import { Typography,Box } from "@mui/material";
import React from 'react'
export const Privacy=()=>{
    React.useEffect(()=>{
        document.title='XGain | Privacy Policy'
      },[])
return(
    <Box sx={{width:'96%',margin:'auto',maxWidth:'1200px',paddingTop:5,textAlign:'justify',px:1}}>
        <Typography  variant="h3">Privacy Policy</Typography><br/>
        <Typography variant="body">
        This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from xgain (the “Site”).</Typography> <br/><br/>
        <Typography variant="body">
        <b> Personal Information we collect</b> <br/>
        When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.
        <br/><br/>
        <b>We collect Device Information using the following technologies</b> <br/> 
        - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
        <br/>- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
        <br/>- “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.
        <br/><br/>Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, payment information (including credit card numbers, email address, and phone number). We refer to this information as “Order Information”.
        <br/><br/>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
        <br/><br/><b>How do we use your Personal Information?</b> <br/> 
        We use the Order Information that we collect generally to fulfill any orders placed through the Site.
        <br/><br/>
        Additionally, we use this Order Information to:
        <br/>- Communicate with you;
        <br/>- Screen our orders for potential risk or fraud; and
        <br/>- When in line with the preferences you have shared with us, provide you with information or
        advertising relating to our products or services.
        <br/>- Display information using third-party apps on our website.
        <br/>- Set up your accounts with our products.
        <br/><br/>
        We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
        <br/><br/><b>Sharing your Personal Information</b> <br/> 
        We share your Personal Information with third parties to help us use your Personal Information, as described above.
        <br/><br/>We also use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
        <br/><br/>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful requests for information we receive, or to otherwise protect our rights.
        <br/><br/><b>Do not track</b> <br/> 
        <br/><br/>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
        <br/><br/><b>Your rights</b> <br/> 
        <br/><br/>If you are a European resident, you have the right to access the personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
        <br/><br/>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the
        Site), or otherwise to pursue our legitimate business interests listed above.
        <br/><br/>Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
        <br/><br/><b>California Residents</b> <br/> 
        California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
        <br/><br/>If you are under 18 years of age, reside in California, and have a registered account with the Site, you have the right to request removal of unwanted data that you publicly post on the Website. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in
        California. We will make sure the data is not publicly displayed on the Website, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
        <br/><br/><b>Data retention</b> <br/> 
        When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
        <br/><br/><b>Changes</b> <br/> 
        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by
        prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
        <br/><br/><b>Contact us</b> <br/> 

        For more information about our privacy practices, if you have questions, or if you would like to
        make a complaint, please contact us by email at support@xgain.ai.
<br/><br/><br/>

        </Typography>
    </Box>
)
}


export default Privacy;