import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import "./App.css";
import {DashboardLayout} from './layout'
import {Home,Register,Login,Verify,OTP,Privacy,TermsOfService, Disclaimer} from './pages/public'
import {MainDashboard,CryptoCategory,Assets,AssetsDetail,AllCoin, BasicDashboard,Activity,MyAccount, Purchase,Notification} from './pages/private'
import ToastContainer from "component/SnackBar";
import theme from "theme";
import PageLayout from "layout/public-page";
import { useDispatch, useSelector } from "react-redux";
import './firebaseConfig'
import AllCoinPublic from "pages/public/all_coin/AllCoin";
import { useEffect } from "react";
import axios from "axios";
import { set_exchanges } from "redux/reducer/crypto";

// my project

function App() {
  const { plan } = useSelector(state => state.user);
  const dispatch=useDispatch()
  useEffect(()=>{
    fetchExchanges();
  },[])

  const fetchExchanges=async()=>{
    try{
      let res=await axios.get('/api/v1/crypto/all-exchanges');
      let temp={};
      res.data?.forEach(obj=>{
        temp[obj.name]={
          img:obj.img,
          link:obj.link
        }
      })
      dispatch(set_exchanges(temp));
    }catch(e){
      console.log(e)
      alert(e)
    }
  }
  return (
    <ThemeProvider theme={theme}>
      
    <Router>
    <ToastContainer/>
      <Routes>
        <Route path="/" element={<PageLayout/>}>
        {/* <Route path="" element={<AllCoinPublic />} /> */}
        <Route path="" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="login/verify" element={<OTP />} />
        <Route path="register" element={<Register />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="term-of-service" element={<TermsOfService />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="verify/:type" element={<Verify />} />
        </Route>
       <Route path="/dashboard" element={<DashboardLayout/>}>
          <Route path="main" element={plan?.name=='Pro'?<MainDashboard/>:<BasicDashboard/>}/>
          <Route path=":portfolio/:category" element={<CryptoCategory/>}/>
          <Route path="assets" element={<Assets/>}/>
          <Route path="all-coin" element={<AllCoin/>}/>
          <Route path="assets/:symbol" element={<AssetsDetail/>}/>
          <Route path="activity" element={<Activity/>}/>
          <Route path="my-account" element={<MyAccount/>}/>
          <Route path="purchase" element={<Purchase/>}/>
          <Route path="notification" element={<Notification/>}/>
          <Route path="*" element={<Navigate to='/dashboard/main' replace/>}/>
        </Route>
        
         {/* <Route path="*" element={<Navigate to='/login'/>}/> */}
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
