import { Box } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import Chart from "react-apexcharts";
import FormateCurrency from "utils/FormateCurrency";

//let temp = [14.7, 23.6, 23.6, 14.4, 5.2, 3.9, 2.8, 2.8, 3.5, 5.5].reverse();



// Actual Box Colors ***********
let colors = [
  "#411C32",
  "#311D41",
  "#421E2B",
  "#18264F",
  "#282B37",
  "#24374D",
  "#113235",
  "#1F362C",
  "#293A35",
  "#401C29",
  "#401C29"
].reverse();


const getDimesion=(ratio=0.22,width=[900,1000,1100,1200,1300,1400,1500,1600,1700,1800,1900,2000,2100,2200,2400,2600,2800,3000,3200])=>{
  return width
  .map(size=>{
   return {
      breakpoint: size, // breakpoint for small devices
      options: {
        chart: {
          width: (size/2)-(size*ratio)
        }
      }
    }
  })
}

const FibChart = ({ values, labels, price, avg_buy, qty,ratio,widths }) => {
  let diffvalues = values.map((value, index) => ((values[index] - values[index + 1] || value))).reverse();

 //console.log({values,diffvalues:diffvalues.reverse()})
  const chartData = useMemo(() => {
    const options = {
      colors: colors,
      labels:{
        show:false
      },
      legend: {
        show: false
      },
      chart: {
        sparkline: { enabled: true },
        foreColor: "#fff",
        stacked: true,
        margin:{
          left:100,
          right:100
        },
        animations:{
          enabled:false
        },
        //   toolbar: {
        //     show: true
        //   }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "100%",
          endingShape: "rounded",
          borderRadius: 4, // Adjust the border radius of the bars
          borderWidth: 1, // Set the border width of the bars
          borderColor: '#fff' // Set the border color of the bars
        }
      },
      xaxis: {
        labels:{
          show:false
        },
        categories: [""]
      },
      tooltip:{
        enabled:false
      },
    
      responsive: [
        {
          breakpoint: 768, // breakpoint for small devices
          options: {
            chart: {
              width: '100%' // 100% width for small devices
            },
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        },
        ...getDimesion(ratio,widths),        
    ],
      yaxis: {
        labels:{
          show:false
        },
        opposite: true,
        min: values[10],
        max: values[0]
      },
      annotations: {
        yaxis: [
          {
            position:'front',
            y: price,
            strokeDashArray: 0,
            borderColor: "rgb(255,255,255)",
            borderWidth: 2,
            offsetX:'94%',
            zIndex:3,
            label: {
              borderColor:  "none",
              marginTop: 10,
              offsetY:5,
              offsetX:'62%',
              position: "left",
              textAnchor: 'left',
              zIndex:20,
              style: {
                color: "rgb(255,255,255)",
                background: "none",
                height: 12,
                fontSize: 12,
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
              },
              text: `Price $${price}`,
              cssClass: 'testing',
            },

          },

          {

            y: avg_buy,
            strokeDashArray: 0,
            borderColor: "rgb(149,242,5)",
            borderWidth: 2,
            offsetX:'94%',
            label: {
              borderColor:  "none",
              offsetY: 5,
              offsetX:'62%',
              position: "left",
              textAnchor: 'left',
              style: {
                color: "rgb(149,242,5)",
                background:  "none",
                height: 12,
                fontSize: 12,
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
              },
              text: `AvBuy ${FormateCurrency(avg_buy||0)}`,
              cssClass: 'testing',
            },

          },
          ...diffvalues.map((value, index) =>
          ({

            y: values[index],
            strokeDashArray: 0,
            borderColor: "none",
            width:'10%',
            label: {
              borderColor:  "none",
              offsetY: index == 0 ? 16 : -2,
              offsetX:'2%',
              marginTop: 10,
              position: "left",
              textAnchor: 'left',
              style: {
                color: "#fff",
                background:  "none",
                height: 12,
                fontSize: 12,
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
              },
              text: `${labels[index]}  ${FormateCurrency(values[index]*1)}     ${(labels[index] !== 'ATL' && price)? (values[index] / price).toFixed(1) + 'X' : ''}`,
              cssClass: 'testing',
            },

          })
          ).reverse(),

        ],
      }
    };
    const series = diffvalues.map((value, index) => ({ name: "Pro1", data: [value] }));
    return { options, series }
  }, [values,ratio])
 
  return <div style={{zIndex:2,height:'100%'}}>
  <Chart    options={chartData.options} series={chartData.series} type="bar" height={'100%'} />
  </div>
};

export default FibChart;

// can you hear me 