import { Modal, Box, Checkbox, TextField, FormControl, FormControlLabel, Typography, Button } from "@mui/material";
import { getCryptoById } from "api/crypto";
import axios from "axios";
import Exchanges from "component/Exchanges";
import { useEffect, useState } from "react";

const AddWallet = ({ toggleWallet, wallet, setWallet, id }) => {
    const exchanges = [
        'Binance',
        'KuCoin',
        'Coinbase',
        'Kraken',
        'MexC',
        'ByBit',
        'HTX',
        'OKX',
        'Gate.io',
        'UniSwap',
        'UpHold',
        'Public',
        'Etoro',

    ];
    const wallets = ['Ledger', 'Trust', 'Coinbase-Wallet', 'Metamask', 'Trezor'];
    const ira = ['iTrust', 'Robinhood'];
    const [selected, setSelected] = useState(wallet);
    const [coinInfo, setCoinInfo] = useState({});
    const [exchangeIcon,setExchange]=useState([]);


    useEffect(() => {
        getCryptoById(id)
            .then((res) => setCoinInfo(res.data))
            .catch(err => console.log(err))
    }, [id])

    useEffect(()=>{
       if(coinInfo.symbol){
        axios.get('/api/v1/crypto/exchange-symbol/'+coinInfo.symbol)
        .then((res)=>setExchange(res.data))
        .catch(()=>{})
       }
    },[coinInfo.symbol])
    const onChange = (e) => {
        if (selected.includes(e.target.name)) {
            let index = selected.indexOf(e.target.name);
            selected.splice(index, 1)
            setSelected([...selected]);
        } else {
            setSelected([...selected, e.target.name])
        }

    }

    const onConfirm = () => {
        let all_coin = [...exchanges, ...wallets, ...ira]
        setWallet(selected.filter(name => all_coin.includes(name)));
    }
    return (
        <Modal
            open={true}
            onClose={toggleWallet}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Box sx={{
                bgcolor: 'primary.light', maxWidth: '750px', display: 'flex', flexWrap: 'wrap', minHeight: 200, px: 5, py: 4,
                borderRadius: 5
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'relative' }}>
                    {coinInfo.symbol &&
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ height: 40 }} component='img' src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${coinInfo?.symbol?.toLowerCase()}.png`} />
                            <Box sx={{ ml: 1 }}>
                                <Typography sx={{ color: "#fff", fontSize: 16, fontWeight: 'bold' }}>{coinInfo?.symbol?.replaceAll('_', '')}</Typography>
                                <Typography sx={{ color: "#fff", fontSize: 12 }}>{coinInfo?.name}</Typography>
                            </Box>
                        </Box>}
                    <Typography sx={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff', position: 'absolute' }} variant="h5">
                       Available @
                    </Typography>

                    <Box>
                       <Exchanges data={exchangeIcon}/>
                    </Box>
                </Box>
                <Box sx={{ width: '100%', marginTop: 2 }}>
                    <Box sx={{ borderBottom: '1px solid #fff', width: '100%', paddingBottom: '2px', marginBottom: 1 }}>
                        <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: 18 }}>My Exchanges</Typography>
                    </Box>
                    <Box>
                        {exchanges.map(name => (<FormControlLabel
                            sx={{ color: '#fff', mr: 3 }}
                            label={name}
                            control={
                                <Checkbox style={{ color: '#fff' }} name={name} type="checkbox" checked={selected.includes(name)} id={`checkbox-${name}`} onChange={onChange} />
                            } />))}
                    </Box>
                </Box>
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <Box sx={{ borderBottom: '1px solid #fff', width: '100%', paddingBottom: '2px', marginBottom: 1 }}>
                        <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: 18 }}>My Wallets</Typography>
                    </Box>
                    <Box>
                        {wallets.map(name => (<FormControlLabel
                            sx={{ color: '#fff', mr: 4 }}
                            label={name}
                            control={
                                <Checkbox style={{ color: '#fff' }} name={name} type="checkbox" checked={selected.includes(name)} id={`checkbox-${name}`} onChange={onChange} />
                            } />))}
                    </Box>
                </Box>
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <Box sx={{ borderBottom: '1px solid #fff', width: '100%', paddingBottom: '2px', marginBottom: 1 }}>
                        <Typography sx={{ color: '#fff', fontWeight: 'bold', fontSize: 18 }}>My IRA Accounts</Typography>
                    </Box>
                    <Box>
                        {ira.map(name => (<FormControlLabel
                            sx={{ color: '#fff', mr: 4 }}
                            label={name}
                            control={
                                <Checkbox style={{ color: '#fff' }} name={name} type="checkbox" checked={selected.includes(name)} id={`checkbox-${name}`} onChange={onChange} />
                            } />))}
                    </Box>
                </Box>
                <Box sx={{ width: '100%', height: 1.1, background: '#fff', marginTop: 3 }} />
                <Box sx={{ display: 'block', marginTop: 2, marginLeft: 'auto' }}>
                    <Button onClick={toggleWallet} sx={{ color: '#fff',  borderRadius: '20px', ml: 2,textTransform:'capitalize' }} variant='outlined'>Cancel</Button>
                    <Button type='submit' sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize'  }} variant="outlined" onClick={onConfirm}>Save</Button>
                </Box>
            </Box>
        </Modal>

    )
}

export default AddWallet;