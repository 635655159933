import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  crypto:[],
  cryptoLoaded:false,
  category:[],
  exchanges:[],
}

export const cryptoSlice = createSlice({
  name: 'crypto',
  initialState,
  reducers: {
    set_crypto: (state,action) => {
        state.crypto=action.payload;
        state.cryptoLoaded=true;
    },
    set_category:(state,action)=>{
      state.category=action.payload;
    },
    set_exchanges:(state,action)=>{
      state.exchanges=action.payload;
    }
    
  },
})

// Action creators are generated for each case reducer function
export const { set_crypto,set_category,set_exchanges} = cryptoSlice.actions

export default cryptoSlice.reducer