import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#fff',
    },
   
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'grey',
        boxShadow:'0px 0px 3px #fff'
      },
    },
  });


// this component is use to accept number only;
const CustomInputDark = ({ label,color,sx,focused,placeHolder,name, value,type,onChange,optional,autoComplete,defaultValue,inputProps,...props}) => {

 
  return (
    <CssTextField
      color={color}
      required={optional?false:true}
      label={label}
      focused={focused}
      fullWidth
      placeholder={placeHolder}
      value={value}
      name={name}
      type={type}
      onChange={onChange}
      sx={{ my: "5px",bgcolor:'#21232D',...sx}}
      InputProps={{ sx: { height: 50, fontSize: 10 } ,endAdornment:props.endAdornment}}
      inputProps={{ style: { fontSize: 14,color:"#fff" ,},...inputProps }} // font size of input text
      InputLabelProps={{ style: { fontSize: 14,color:'#fff'} }} // font size of input label
      autoComplete={autoComplete}
      defaultValue={defaultValue}
    />
  );
};

export default CustomInputDark;

