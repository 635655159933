import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { updateSell } from "api/crypto";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { set_message } from "redux/reducer/message";
import FormateCurrency from "utils/FormateCurrency";
import FormatToIntCurrency from "utils/FormatToIntCurrency";
import ExchangeandWallet from "component/ExchangeWallet";
import LogoText from "component/Logo";
import EditCrypto from "component/Modal/EditCrypto";

const SellTable = ({
    sell1_price, sell2_price, sell3_price, sell4_price,
    sell1_set, sell2_set, sell3_set, sell4_set,
    qty, avg_buy, setSets, id,invested,fetchData,fetchAll,
    symbol,
    wallet
}) => {
    const dispatch = useDispatch();
    const [temp,setTemp]=useState({grand_total_sell:0,total_profit_sell:0})
    const [editing,setEditing]=useState(false);
    const handleChange = (e) => {
        setSets(prev => ({ ...prev, [e.target.name]: e.target.value.replace(/[^0-9.]+/g, '').replace(/^(\d*\.\d{0,1})\..*/, '$1')}))
    }

   
    const qtyData = useMemo(() => {
        let temp = {};
        temp.sell1_qty = ((qty * sell1_set) / 100)
        temp.sell2_qty = (((qty - temp.sell1_qty) * sell2_set) / 100)
        temp.sell3_qty = (((qty - (temp.sell1_qty + temp.sell2_qty)) * sell3_set) / 100)
        temp.sell4_qty = (((qty - (temp.sell1_qty + temp.sell2_qty + temp.sell3_qty)) * sell4_set) / 100)

        temp.sell1_roi = sell1_price * temp.sell1_qty;
        temp.sell2_roi = sell2_price * temp.sell2_qty;
        temp.sell3_roi = sell3_price * temp.sell3_qty;
        temp.sell4_roi = sell4_price * temp.sell4_qty;
        temp.grand_total_sell=temp.sell1_roi+temp.sell2_roi+temp.sell3_roi+temp.sell4_roi;
        temp.total_profit_sell=temp.grand_total_sell-invested;
       
        Object.keys(temp).forEach((key) => {
            temp[key] = temp[key].toFixed(4);
        })

        temp.xgain_sell1 = (temp.sell1_roi / (temp.sell1_qty * avg_buy)).toFixed(1)
        temp.xgain_sell2 = (temp.sell2_roi / (temp.sell2_qty * avg_buy)).toFixed(1)
        temp.xgain_sell3 = (temp.sell3_roi / (temp.sell3_qty * avg_buy)).toFixed(1)
        temp.xgain_sell4 = (temp.sell4_roi / (temp.sell4_qty * avg_buy)).toFixed(1)
        temp.total_gain=temp.grand_total_sell/invested;

        return temp;
    }, [sell1_set, sell2_set, sell3_set, sell4_set, sell1_price, sell2_price, sell3_price, sell4_price, avg_buy])

    const handleSubmit = (e) => {
        let data = {
            sell1_price, sell2_price, sell3_price, sell4_price,
            sell1_set, sell2_set, sell3_set, sell4_set,
            grand_total_sell: qtyData.grand_total_sell,
            total_profit_sell:qtyData.total_profit_sell
        }
        updateSell(data, id)
            .then(() => {
                fetchAll&&fetchAll();
                fetchData();
                dispatch(set_message('saved !!'))})
            .catch((err) => {
                console.log(err)
                dispatch(set_message('something went wrong !!'))
            })
    }
    const handleFocus = (e) => {
        e.target.select();
    }

    const handleClose=()=>{
        if(editing){
            fetchData()
            fetchAll();
        }
        setEditing(false)
    
    }
    return (<Box sx={{ paddingX: 2, marginY: {xs:3,sm:0}, width: '100%',height:'max-content' }}>
        <Typography variant="h5" sx={{color:'#fff',fontWeight:'bold',textAlign:'left'}}>{symbol?.replaceAll('_','')} Exit Strategy</Typography>
        
        <TableContainer sx={{pb:0,height:'max-content',overflowY:'hidden'}} >
        <Table>
            <TableHead>
               <TableRow>
               <TableCell  sx={{ color: "#fff",  paddingY: 1,textAlign:'left' }}>Exit %</TableCell>
               {/* <TableCell  sx={{ color: "#fff",  paddingY: 1,textAlign:'left' }}>%</TableCell> */}
               <TableCell  sx={{ color: "#fff",  paddingY: 1,textAlign:'left' }}>Sell @</TableCell>
               <TableCell  sx={{ color: "#fff",  paddingY: 1,textAlign:'left' }}><LogoText fontSizeTm={5}/></TableCell>
               <TableCell  sx={{ color: "#fff",  paddingY: 1,textAlign:'left' }}>Qty</TableCell>
               <TableCell  sx={{ color: "#fff",  paddingY: 1,textAlign:'left' }}>pROI</TableCell>
               </TableRow> 
            </TableHead>
            <TableBody>
                <TableRow >
                    {/* <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>1st</TableCell> */}
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5, display: 'flex', alignItems: 'center' }}>#1 . 
                        <TextField
                            value={sell1_set || ''}
                            sx={{ width: 55, fontSize: 16, input: { color: '#fff' }, marginRight: 1 }} name="sell1_set"
                            onChange={handleChange}
                            onFocus={handleFocus}
                            inputMode="decimal"
                            inputProps={{ style: { height: 2, fontSize: 14, bgcolor: '#7A8EBC',inputMode: 'decimal' } }}
                            InputProps={{ style: { background: '#7A8EBC' } }} /> %
                    </TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{FormatToIntCurrency(sell1_price)}</TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5, minWidth: 80 }}>{qtyData.xgain_sell1}<span className="x">X</span></TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{qtyData.sell1_qty}</TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{FormatToIntCurrency(qtyData.sell1_roi)} </TableCell>
                </TableRow>

                <TableRow >
                    {/* <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>2nd</TableCell> */}
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5, display: 'flex', alignItems: 'center' }}>#2 .
                        <TextField
                            value={sell2_set}
                            sx={{ width: 55, fontSize: 16, input: { color: '#fff' }, marginRight: 1 }} name="sell2_set"
                            onChange={handleChange}
                            onFocus={handleFocus}
                            inputMode="decimal" 
                            inputProps={{ style: { height: 2, fontSize: 14,inputMode: 'decimal'  } }}
                            InputProps={{ style: { background: '#7A8EBC' } }} />%
                    </TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{FormatToIntCurrency(sell2_price)}</TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{qtyData.xgain_sell2}<span className="x">X</span></TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{qtyData.sell2_qty} </TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{FormatToIntCurrency(qtyData.sell2_roi)} </TableCell>
                </TableRow>

                <TableRow >
                    {/* <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>3rd</TableCell> */}
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5, display: 'flex', alignItems: 'center' }}>#3 .
                        <TextField
                            value={sell3_set}
                            sx={{ width: 55, fontSize: 16, input: { color: '#fff' }, marginRight: 1 }}
                            name="sell3_set" onChange={handleChange}
                            onFocus={handleFocus}
                            inputMode="decimal"
                            inputProps={{ style: { height: 2, fontSize: 14,inputMode: 'decimal'  } }}
                            InputProps={{ style: { background: '#7A8EBC' } }} /> %
                    </TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{FormatToIntCurrency(sell3_price)}</TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{qtyData.xgain_sell3}<span className="x">X</span></TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{qtyData.sell3_qty}</TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{FormatToIntCurrency(qtyData.sell3_roi)} </TableCell>
                </TableRow>
                <TableRow >
                    {/* <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>4th</TableCell> */}
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5, display: 'flex', alignItems: 'center' }}>#4 .
                        <TextField
                            value={sell4_set}
                            sx={{ width: 55, fontSize: 16, input: { color: '#fff' }, marginRight: 1 }}
                            name="sell4_set" onChange={handleChange}
                            onFocus={handleFocus}
                            inputMode="decimal"
                            inputProps={{ style: { height: 2, fontSize: 14,inputMode: 'decimal'  } }}
                            InputProps={{ style: { background: '#7A8EBC' } }}
                        /> %
                    </TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{FormatToIntCurrency(sell4_price)}</TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{qtyData.xgain_sell4}<span className="x">X</span></TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{qtyData.sell4_qty}</TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>{FormatToIntCurrency(qtyData.sell4_roi)}</TableCell>
                </TableRow>
                <TableRow >
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}></TableCell>
                    {/* <TableCell></TableCell> */}
                    <TableCell></TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>Tot. 
                    {qtyData.total_gain?.toFixed(1)}<span className="x">X</span>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ color: "#fff", borderBottom: 'none', paddingY: 0.5 }}>Tot.
                    {FormatToIntCurrency(qtyData.grand_total_sell)}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        <Box>
            <Box>
            <Button onClick={handleSubmit}
            id="save-sell"
           sx={{
               cursor: 'pointer',
               display:'block',
               marginTop: 2, 
               marginLeft: 'auto',
               mr:2,
               marginBottom: 0.5,
               padding: '5px 15px',
               border: '1px solid grey', borderRadius: 10
           }}
           variant="contained">Save</Button>
            </Box>
       
        </Box>
      
        <Box>
         
        </Box>
    </Box>)
}

export default SellTable;