import { Card } from "@mui/material";
import React, { useMemo } from "react";
import Chart from 'react-apexcharts'
import FormatToIntCurrency from "utils/FormatToIntCurrency";

const LineChart=({chartData=[]})=>{
    const series= useMemo(()=>{
        return [
         {
            name: 'Invested',
            data:chartData.map((obj)=>({
                x: new Date(obj.createdAt),
                y:obj.invested,
            }))
        },
         {
            name: 'Profit',
            data:chartData.map((obj)=>({
                x: new Date(obj.createdAt),
                y:(obj.worth-obj.invested),
            }))
        },
        {
          name: 'worth',
          data:chartData.map((obj)=>({
              x: new Date(obj.createdAt),
              y:obj.worth,
          }
        ))},]
    },[chartData])
   const options=useMemo(()=>{
    return      {
      chart: {
      type: 'area',
      foreColor: '#fff',
      toolbar: {
        show: false,
    },
      zoom: {
        type: 'x',
        enabled: false,
       
      },
      events : {
        beforeZoom : (e, {xaxis}) => {
  
            let maindifference =((new Date(chartData[chartData.length-1]?.createdAt).valueOf()-new Date(chartData[0]?.createdAt).valueOf()))
            let zoomdifference =   xaxis.max - xaxis.min ;
            if( zoomdifference > maindifference )
            return  {
                // dont zoom out any further
                xaxis: {
                    min:chartData[0].createdAt,
                    max: chartData[chartData.length-1].createdAt,
                }
            }; 
            else {
                return {
                    // keep on zooming
                    xaxis: {
                        min: xaxis.min,
                        max: xaxis.max
                    }
                }
            }
        }
    },
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
    },
    fill: {
      opaciy:1,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return FormatToIntCurrency(val)
        },
      },
      
    },
    xaxis: {
      type: 'datetime',
      labels:{
          datetimeUTC:false
      }
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return FormatToIntCurrency(val)
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart:{
         
          toolbar: {
            show: false,
        },
        },
          
      }
  }]
    };
   },[chartData])

    return(
        <Chart
          height='100%'
          width='100%' 
          id='chartContainer'
          series={series}
          options={options}
          type='area'
        />
    )
}
export default LineChart;