import axios from 'axios'
import { CategoryChartLayout } from 'layout'
import { CoinTable } from 'component/Table'
import { TableCoinData } from 'data'
import { Box } from '@mui/material'
import BottomTab from 'component/BottomTab'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { getCategoty, mycoin } from 'api/crypto'
import CategoryTable from 'layout/dashboard/category/category-table-layout'
import { useDispatch, useSelector } from 'react-redux'
import { set_crypto } from 'redux/reducer/crypto'
import addEvent from 'utils/addEvent'

export const BasicDashboard = () => {
    const {crypto} = useSelector(state=>state.crypto)
    const dispatch=useDispatch();
    const [total, setTotal] = useState({ total_invested: 0, total_sell: 0 });
    
    useEffect(() => {
        fetchData();
        let timer=setInterval(()=>{
            fetchData();
           },30000);
          addEvent('Basic Dashboard')
        return ()=>clearInterval(timer)
    }, [])

    const fetchData = useCallback(async () => {
        mycoin()
            .then(res => {
                //setCrypto(res.data?.data?.[0]?.assets||[])
                dispatch(set_crypto(res.data?.data?.[0]?.assets||[]))
                res.data.total_data[0]&&setTotal(res.data.total_data[0])
            })
    }, [])
    return (<Box>
        <CategoryChartLayout total_invested={total.total_invested} total_sell={total.total_sell} crypto={crypto} category_name={''} />
        
            <CategoryTable key={Math.random()} refresh={fetchData} data={crypto} />
       
        <BottomTab />
    </Box>)
}
