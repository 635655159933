import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Modal, Typography } from '@mui/material';
import { useState } from 'react';

const mappper=[
    {label:"Quantity",value:"qty"},
    {label:"Price",value:"price"},
    {label:"Average Buy",value:"avg_buy"},
    {label:"Invested",value:"invested"},
    {label:"Xgain",value:"xgain"},
    {label:"Worth",value:"worth"},
    {label:"P&L",value:"p_and_l"},
    {label:"PXgain",value:"pXgain"},
    {label:"PRoI",value:"pRoI"},
    {label:"XATH",value:"xATH"},
    {label:"DeltaATH",value:"deltaATH"},
    {label:"DeltaHr",value:"deltaHr"},
    {label:"DeltaDay",value:"deltaDay"},
    {label:"DeltaWeek",value:"deltaWeek"},
    {label:"DeltaMonth",value:"deltaMonth"},
    {label:"DeltaYear",value:"deltaYear"},
    {label:"Hr24Vol",value:"Hr24Vol"},
    {label:"MktCap",value:"mktCap"},
    {label:"Trade",value:"trade"},
]

const TableColumnModal = ({column,setColums}) => {
    const [selected, setSelected] = useState(column);
    
    const applyColumn=()=>{
        setColums(selected);
    }
    return (
        <Modal
            open={true}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                
            }}
        >
            <Box sx={{
                bgcolor: 'primary.light', maxWidth: '750px', display: 'flex', flexWrap: 'wrap', minHeight: 200, px: 5, py: 4,
                borderRadius: 5,
                
            }}>
                <FormGroup>
                    <Typography color="#fff" textAlign="center" mb={5} variant="h6">Select Columns</Typography>
                    <Grid maxWidth={"500px"} width={"95%"} gridColumn={12} container>
                    {mappper.map((item) => (
                        <Grid item bg="blue" xs={6} md={4}>
                        <FormControlLabel
                            sx={{ color: '#fff', mr: 3 }}
                            control={<Checkbox
                                style={{ color: '#fff' }}
                                checked={selected[item.value]}
                                onChange={(e) => {
                                    setSelected({ ...selected, [item.value]: e.target.checked })
                                }}
                            />}
                            label={item.label}
                        />
                        </Grid>
                    ))}
                    </Grid>
                    <Button  sx={{mt:5,mx:'auto',w:"max-content", color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize'  }} variant="outlined" onClick={applyColumn}>Apply</Button>
                </FormGroup>
            </Box>
        </Modal>)
        
}

export default TableColumnModal;