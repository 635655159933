import { Select,InputLabel,MenuItem } from "@mui/material";
import styled from "@emotion/styled";
const CssSelect = styled(Select)({
    '& label.Mui-focused': {
      color: '#fff',
    },
   
    '& .MuiOutlinedSelect-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
        boxShadow:'0px 0px 3px #fff'
      },
    },
  });

const CustomeSelect = ({name='select',label='Select',value,option=[],onChange,customLabel=false,readOnly=false}) => {
  return (
        <>
            <InputLabel key={name +'input'} sx={{color:"#fff"}}>{label}</InputLabel>
            <CssSelect
                key={name}
                label={label}
                name={name}
                readOnly={readOnly}
                onChange={onChange}
                defaultValue={value}
                required
                sx={{color:'#fff',border:'1px solid grey',
                bgcolor:'#21232D',
                "& .MuiSvgIcon-root": {
                    color: "white",
                },
            }}
                fullWidth
            >
              {customLabel?
              option.map(({value,label})=> <MenuItem  value={value}>{label}</MenuItem>):
              option.map(optionValue=> <MenuItem  value={optionValue}>{optionValue=='Goldv2'?'Bluechip':optionValue}</MenuItem>)
              }
               
            </CssSelect>
        </>
    )
}

export default CustomeSelect;