import { Box } from "@mui/material"

const Section=({children,sx,maxHeight,height,id})=>{
    return (
        <Box id={id} sx={{width:'100%',height:{xs:'max-content',...height},
        minHeight:{lg:'80vh',md:'800px',sm:'680px',xs:'650px',},
        maxHeight:{md:'1366px',...maxHeight},
        ...sx}}>
            {children}
        </Box>
    )
}

export default Section;