import React,{memo} from 'react';
import { AdvancedChart } from 'react-tradingview-embed';
import { Box } from '@mui/material';

const  TradingViewChart=({symbol})=>{
  return (
    <Box sx={{mt:2}}>
        <AdvancedChart
          widgetProps={{
            theme: 'dark',
            symbol: `${symbol}USDT`, 
            // USDT, USDC, 
            allow_symbol_change: true,
            toolbar_bg: '#fff',
            height:'100%',
            flex:1,
            // hide_top_toolbar:true,
            // hide_side_toolbar:true,
          }}
        />
    </Box>
  );
}

export default memo(TradingViewChart);