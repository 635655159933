import React, { useEffect, useState } from "react";
import { Box, Link, Typography, Button, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import CustomInput from "../../../component/Input/CustomInput";
import CustomButton from "../../../component/Buttons/CustomButton";
import PrivacyPolicyText from "../../../component/PrivacyPolicyText";
import { useDispatch, useSelector } from "react-redux";
import { verify,sendCode, resendCode} from 'api/auth'
import { set_message } from "redux/reducer/message";
import { set_user,set_plan } from "redux/reducer/user";
import { MarkEmailRead, PermPhoneMsg } from "@mui/icons-material";
export const Verify = () => {
  const { type } = useParams();
  const [otp, setOtp] = useState('');
  const navigate = useNavigate()
  const VerificationType = type === "phone" ? "Phone" : "Email";
  const is_phone = type === "phone" ? true : false;
  const { user } = useSelector(state => state.user);
  const ALPHA_NUMERIC_DASH_REGEX = /^[0-9-]+$/;
  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();
  const ref6 = React.useRef();
  const refArry = [ref1, ref2, ref3, ref4, ref5, ref6];
  const [error,setError]=React.useState(null);
  const [counter, setCount] = React.useState(0);
  const dispatch=useDispatch();
  useEffect(()=>{
    if(!user){
      navigate('/login')
    }
  },[user])

  React.useEffect(()=>{
    ref1.current.focus();
  },[navigate])

  const startTimer= React.useCallback(()=>{
    setCount(30);
    let interval=setInterval(()=>{
      setCount(prev=>{
        if(prev==0){
          return clearInterval(interval);
        }
        return prev-1;
      })
    },1000)
  })
  const handleChange = (e) => {
    if (e.key === "Backspace") {
      if (e.target.name === "1") {
        setOtp("")
        return
      }
      if (otp[e.target.name - 1] !== undefined) {
        setOtp(prev => prev.slice(0, -1))
      }
      refArry[e.target.name - 2].current.focus();
    } else {

      if (e.target.name === "6") {
        if (otp[5]) {
          let new_otp = otp.slice(0, -1) + e.key.replace(otp[5], "")
          setOtp(new_otp)
        } else {
          setOtp(prev => prev + e.key);
        }

      } else {
        setOtp(prev => prev + e.key.replace(otp[e.target.name - 1], ""));
        refArry[parseInt(e.target.name)].current.focus();
      }
    }
  }

  const handleSubmit = (e) => {
    e?.preventDefault();
    let data = { code: otp };
    if (is_phone) {
      data.country_code = user.country_code;
      data.phone = user.phone;
    } else {
      data.email = user.email;

    }
    verify(VerificationType, data)
      .then((res) => {
        if (is_phone) {
          setOtp('')
          navigate("/verify/email")
        } else {
    
                setTimeout(()=>{
                  window.location.href='/dashboard/main';
                },200)
        }
      }).catch(err=>{
        if(err.response.status<500 && err.response.status>=400){
         
          setError(err.response.data)
        }else{
          setError('Ooops, something went wrong!')
        }
      })
  }

  const getScreatEmail = () => {
    if (!user) {
      return ""
    }
    let text = user?.email?.split('@')[0]||'';
    let hideCount = text.length - 3;
    let ogEmail = user.email;
    return "*".repeat(hideCount) + ogEmail.slice(hideCount);
  }

  useEffect(() => {
    if (otp.length === 6) {
      handleSubmit();
    }
  }, [otp, handleSubmit])

  const handleKeypress = event => {
    if (event.key !== 'Backspace' && !ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
      event.preventDefault();
    } else {
      handleChange(event)
    }
  }


  const resend_code = () => {
    if (counter) return;
    setOtp('');
    setError(null)
    ref1.current.focus();
    let username=is_phone?user.country_code+" "+user.phone : user.email;
    resendCode(username)
      .then(() => {
        startTimer();
        dispatch(set_message('CODE sent to ' + username))
      })
      .catch((err) => dispatch(set_message(err.response.data.msg)))
  }


  return (
    <Box
      component="form"
      sx={{
        maxWidth: 400,
        width: "92%",
      }}
      mx="auto"
      autoComplete="off"
    >
      <Box
        display="flex"
        sx={{ justifyContent: "center", alignItems: "center", py: 3 }}
      >
        <img src={logo} height="120px" alt="logo" />
      </Box>
      <Typography my={3} fontWeight="bold" textAlign={'center'} variant="h6">
        Check Your {VerificationType}
      </Typography>
      <Box>
        <Typography width='100%' textAlign={'center'}>
         {type=='phone'?
          <PermPhoneMsg sx={{fontSize:150,color:'green'}} />
          :
          <MarkEmailRead sx={{fontSize:150,color:'green'}}/>
        }
        </Typography>
      </Box>
      <Typography textAlign={'center'} fontSize={14}>
        Verification code sent to
      </Typography>
      <Typography
        fontSize={14}
        variant="subtitle"
        display="block"
        fontWeight="bold"
        my={"5px"}
        textAlign={'center'}
      >
        {VerificationType === "Phone"
          ? `XX-XXXX-${user?.phone?.slice(6)}`
          : `${getScreatEmail()}`}
      </Typography>
      <Typography textAlign={'center'} fontSize={13}>Please enter the CODE below.</Typography>
      <Box mt={5}>
        {/* <CustomInput value={otp} onChange={handleChange} label="Enter Code" /> */}
        <Box>
        <Typography sx={{color:'red',textAlign:'center'}}>{error}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }} mt={2}>
          <TextField error={error} onKeyDown={handleKeypress}  type="number" name="1" value={otp[0] || ""} inputRef={ref1} inputProps={{inputMode: 'numeric', style: { fontWeight: 700, maxLength: 1, textAlign: 'center' } }} sx={{ mx: 1, fontSize: 20, }} />
          <TextField error={error} onKeyDown={handleKeypress}  type="number" name="2" value={otp[1] || ""} inputRef={ref2} inputProps={{inputMode: 'numeric', style: { fontWeight: 700, maxLength: 1, textAlign: 'center' } }} sx={{ mx: 1, fontSize: 20, }} />
          <TextField error={error} onKeyDown={handleKeypress}  type="number" name="3" value={otp[2] || ""} inputRef={ref3} inputProps={{inputMode: 'numeric', style: { fontWeight: 700, maxLength: 1, textAlign: 'center' } }} sx={{ mx: 1, fontSize: 20, }} />
          <TextField error={error} onKeyDown={handleKeypress}  type="number" name="4" value={otp[3] || ""} inputRef={ref4} inputProps={{inputMode: 'numeric', style: { fontWeight: 700, maxLength: 1, textAlign: 'center' } }} sx={{ mx: 1, fontSize: 20, }} />
          <TextField error={error} onKeyDown={handleKeypress}  type="number" name="5" value={otp[4] || ""} inputRef={ref5} inputProps={{inputMode: 'numeric', style: { fontWeight: 700, maxLength: 1, textAlign: 'center' } }} sx={{ mx: 1, fontSize: 20, }} />
          <TextField error={error} onKeyDown={handleKeypress}  type="number" name="6" value={otp[5] || ""} inputRef={ref6} inputProps={{inputMode: 'numeric', style: { fontWeight: 700, maxLength: 1, textAlign: 'center' } }} sx={{ mx: 1, fontSize: 20, }} />
        </Box>
        {counter?<Typography>retry in {counter}s</Typography>:
         <Button
         onClick={resend_code}
          variant="text"

          color="secondary"
          sx={{
            textTransform: "initial",
            letterSpacing: "1px",
            fontSize: 14,
            wordSpacing: -1,
            color:'#000'
          }}
        >
          Send another CODE
        </Button>
        }
       
      </Box>
      <Box my={3} textAlign="center">
        <Typography variant="p" color="grey">
          Have an Account?
          <Link href='/login'  sx={{ ml:1,color:'#1976d2',textDecoration:'none' }} variant="primary">
            Login
          </Link>
        </Typography>
      </Box>
      <CustomButton onClick={handleSubmit} text={`Verify ${VerificationType}`} />
      <PrivacyPolicyText />
    </Box>
  );
};

export default Verify;
