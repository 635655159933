import { Table, TableBody, TableCell, TableHead, TableRow,Box, Typography,Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_unread_notification } from "redux/reducer/user";
import addEvent from "utils/addEvent";
import { useNavigate } from "react-router-dom";


export const Notification = () => {
    const [notification,setNotification]=useState([]);
    const navigateTo=useNavigate();
    const dispatch=useDispatch();
    useEffect(() => {
        fetch('/api/v1/user/notification')
        .then(res=>res.json())
        .then(res=>setNotification(res.notifications))
        
        axios.post('/api/v1/user/notification-read-time')
        dispatch(set_unread_notification(0))
       addEvent("View Notification")
     }, [])

    const goToHome=()=>{
       navigateTo('/dashboard/main')
    }

    return (

        <div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Typography variant="h5" color="#fff" gutterBottom>NOTIFICATIONS</Typography>
            <Button onClick={goToHome} sx={{ color: '#fff', px: 4, borderColor: "#fff", borderRadius: '20px' }} variant="outlined">Home</Button>
        </Box>

        <Table >
            <TableHead>
                <TableRow>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">#</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Date Time</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Title</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Symbol</TableCell>
                    <TableCell sx={{color:"#fff"}} className="text-light" scope="col">Message</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {notification.map((element,index)=>
                (
                    <TableRow style={{borderBottomColor:'rgb(33, 35, 46)'}}>
                        <TableCell sx={{color:"#fff"}} className="text-light">{index+1}</TableCell>
                        <TableCell sx={{color:"#fff"}} className="text-light">{new Date(element.createdAt).toLocaleString()}</TableCell>
                        <TableCell sx={{color:"#fff"}} className="text-light"> {element.title} </TableCell>
                        <TableCell sx={{color:"#fff"}} className="text-light"> 
                        <Box component='img' src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/${element.symbol?.toLowerCase()}.webp`}/>
                        </TableCell>
                        <TableCell sx={{color:"#fff"}} className="text-light"> {element.body} </TableCell>
                        
                    </TableRow>
                )
              )}
            </TableBody>
        </Table>
        </div>
    )
}

export default Notification;