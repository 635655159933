import { Card } from "@mui/material";
import React from "react";
import Chart from 'react-apexcharts'
import FormatToIntCurrency from "utils/FormatToIntCurrency";

export default class ColumnChart extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            series: [{
                name: 'Invested',
                data: this.props.invested || [1, 2, 3, 4]
            }, {
                name: 'Worth',
                data: this.props.worth || [5, 6, 7, 8]
            }
            ],
            options: {
                chart: {
                    sparkline: { enabled: false },
                    type: 'bar',
                    foreColor: '#fff',
                    toolbar: {
                        show: false,
                    },

                    events: {
                        mounted: (chart) => {
                            chart.windowResizeHandler();
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    labels:{
                        show:false
                    },
                    categories: this.props.categories || [],
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return FormatToIntCurrency(val)
                        }
                    },
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    style: {
                        fontSize: '12px',
                        color: 'green',
                        background: 'blue'
                    },
                    y: {
                        min:0,
                        formatter: function (val) {
                            return "$" + val.toFixed(0) + " "
                        }
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            show: true,
                        }
                    }
                }]
            },


        };
    }

//    componentDidMount(){
//     if(this.props.roi) {
//         this.setState({series:[...this.state.series,{
//             name:"ROI",
//             data:this.props.roi
//         }]})
//     }
//    }

    render() {
        return (
            <Card  elevation={0} sx={{overflow:'visible',bgcolor:'primary.light',minHeight: {xs:250,sm:'100%'}, padding:2,boxShadow:'0px 0px 2px #fff',width:'100%',height:'100%'}}>
                <Chart key={Math.random()} width='100%' height={'100%'} id='chartContainer' options={this.state.options} series={this.state.series} type="bar" />
            </Card>
        );
    }
}

