import { useCallback, useMemo } from "react";
import { TableCategoryOverview } from "component/Table";
import FormateCurrency from "utils/FormateCurrency";
import LogoText from "component/Logo";
import { useSelector } from "react-redux";
const FormateData = (title, total,data = [] ) => {
    var temp = {
        total_invested: 0,
        total_value: 0,
        fib0618: 0,
        fib0786: 0,
        fib1: 0,
        fib1618: 0,
        fib2618: 0,
        fib3618: 0,
        fib4236: 0,
        grand_total_sell: 0,
    }
    data.filter(obj => {
        temp.total_invested = (obj.invested || 0) + temp.total_invested
        temp.grand_total_sell = (obj.grand_total_sell || 0) + temp.grand_total_sell
        temp.total_value = (obj.price * obj.qty || 0) + temp.total_value
    });
    return [
        <strong>{title}</strong>,
        FormateCurrency(temp.total_invested),
        ((temp.total_invested / total) * 100).toFixed(1) + "%",
        FormateCurrency(temp.total_value),
        <span style={{ color: (temp.total_value - temp.total_invested) < 0 ? 'red' : '#95F204', fontWeight: 'bold' }}>
            {FormateCurrency((temp.total_value - temp.total_invested))}
        </span>,
         <p>{
            isNaN(temp.total_value / temp.total_invested)?0:
            (temp.total_value / temp.total_invested).toFixed(1)
            }<span className="x">X</span></p>,
        <span >
            {FormateCurrency((temp.grand_total_sell))}
        </span>,
        <p>{
            isNaN(temp.grand_total_sell / temp.total_invested)?0:
            (temp.grand_total_sell / temp.total_invested).toFixed(1)
            }<span className="x">X</span>
        </p>,
    ]

}
const DashboardTable = ({ crypto }) => {
    const category=useSelector(state=>state.crypto.category)
    const dataCollection = useMemo(() => {
        let temp = {}
        temp.allData = { data: crypto };
        temp.allData.total_invested = crypto.map(obj => obj.invested).reduce((a, b) => a + b, 0)
        for(let {_id,name} of category){
            temp[name]= { data: crypto.filter((obj => obj.categoryId == _id)) }
        }
        // temp.Goldv2 = { data: crypto.filter((obj => obj.category == 'Goldv2')) }
        // temp.BigCap = { data: crypto.filter((obj => obj.category == 'BigCap')) }
        // temp.DeFi = { data: crypto.filter((obj => obj.category == 'De-Fi')) }
        // temp.Metaverse = { data: crypto.filter((obj => obj.category == 'Metaverse')) }
        // temp.NFT = { data: crypto.filter((obj => obj.category == 'NFT')) }
        // temp.LowCap = { data: crypto.filter((obj => obj.category == 'LowCap')) }
        return temp;
    }, [crypto,category]);

    const prepared_data = useMemo(() => {
        let temp = {};
        temp.headers = ['Category','Invested', 'Allocation','Worth', 'P&L',<LogoText fontSize={'18px'}  fontSizeTm={'10px'}/>, 'pROI',,<><span>p</span> <LogoText fontSize={'18px'}  fontSizeTm={'10px'}/></>,];
        temp.rows=category.map(({name,_id})=>{
            return FormateData(name,dataCollection.allData.total_invested,dataCollection[name].data)
        })
            
            // FormateData('BigCap',dataCollection.allData.total_invested ,dataCollection.BigCap.data),
            // FormateData('DeFi',dataCollection.allData.total_invested, dataCollection.DeFi.data ),
            // FormateData('MetaVerse',dataCollection.allData.total_invested,dataCollection.Metaverse.data),
            // FormateData('Gaming',dataCollection.allData.total_invested, dataCollection.NFT.data),
            // FormateData('HighX',dataCollection.allData.total_invested, dataCollection.LowCap.data),
        
        return temp
    }, [dataCollection])
    return (
        <TableCategoryOverview {...prepared_data}/>
    )
}

export default DashboardTable;