import { Typography } from "@mui/material";

const LogoText=({fontSize,fontSizeTm,sx})=>{
  return  (
  <>
  <Typography component='span' sx={{fontFamily:'inherit',fontSize,fontWeight:'bold',...sx}}><span style={{color: 'red',}}>X</span>Gain</Typography><Typography component={'sup'} sx={{fontSize:fontSizeTm,fontFamily:'inherit',fontWeight:'bold'}} className='trade-mark'>TM</Typography>
  </>
  )
}

export default LogoText;