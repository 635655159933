import { useEffect, useRef, useState } from 'react';
import FormateCurrentcy from '../../../utils/FormateCurrency';
import FibSlider from '../../Slider/FibSlider';
import './CustomeChart.css'
import FibChart from '../FibChart';
import { Box } from '@mui/material';



function adjustLine(from, to, line){

    var fT = from.offsetTop  + from.offsetHeight/2;
    var tT = to.offsetTop    + to.offsetHeight/2;
    var fL = from.offsetLeft + from.offsetWidth/2;
    var tL = to.offsetLeft   + to.offsetWidth/2;
    
    var CA   = Math.abs(tT - fT);
    var CO   = Math.abs(tL - fL);
    var H    = Math.sqrt(CA*CA + CO*CO);
    var ANG  = 180 / Math.PI * Math.acos( CA/H );
  
    if(tT > fT){
        var top  = (tT-fT)/2 + fT;
    }else{
        var top  = (fT-tT)/2 + tT;
    }
    if(tL > fL){
        var left = (tL-fL)/2 + fL;
    }else{
        var left = (fL-tL)/2 + tL;
    }
  
    if(( fT < tT && fL < tL) || ( tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL)){
      ANG *= -1;
    }
    top-= H/2;
  
    line.style["-webkit-transform"] = 'rotate('+ ANG +'deg)';
    line.style["-moz-transform"] = 'rotate('+ ANG +'deg)';
    line.style["-ms-transform"] = 'rotate('+ ANG +'deg)';
    line.style["-o-transform"] = 'rotate('+ ANG +'deg)';
    line.style["-transform"] = 'rotate('+ ANG +'deg)';
    line.style.top    = top+'px';
    line.style.left   = left+'px';
    line.style.height = H + 'px';
  }

function Linedraw({startRef,endRef})
{
    const [data,setData]=useState({ax:0,ay:0,calc:0,length:0});
    const lineRef=useRef();
    useEffect(()=>{
       adjustLine(startRef.current,endRef.current,lineRef.current)
       window.addEventListener('resize',()=>{
        adjustLine(startRef.current,endRef.current,lineRef.current)
       })
        return ()=>{
            window.removeEventListener('resize',()=>{adjustLine(startRef.current,endRef.current,lineRef.current)});
        }
    },[])
   // console.log(data)
    
    return(
        <div id='line' 
        ref={lineRef}
       
            >
            </div>
    )

}

const CustomeChart = ({symbol,qty,fib0236,fib0382,fib0500,fib0618,fib0786,ATH,
fib1618,fib2618,fib3618,fib4236,price_data,setPrice,OG_ATL,ATL,price,avg_buy,price_avg_buy}) => {

    return (
        <Box key={symbol} sx={{display:'flex',width:500,height:"60vh"}} minHeight={200} className="custom-chart-area d-flex">
           <FibChart
           values={[
            fib4236,
            fib3618,
            fib2618,
            fib1618,
            ATH,
            fib0786,
            fib0618,
            fib0500,
            fib0382,
            fib0236,
            (OG_ATL||ATL)*1,
           ]}
           price={price}
           avg_buy={(avg_buy)}
           qty={qty}
           labels={['Fib(4.236)','Fib(3.618)','Fib(2.618)','Fib(1.618)','2021 ATH','Fib(0.786)','Golden Ratio','Fib(0.5)','Fib(0.382)','Fib(0.236)','SwingLow']}
           />

            <div style={{height:'100%',zIndex:2}} >
                {((OG_ATL||ATL) && fib4236) &&
                <FibSlider 
                key={Math.random()}
                domain={[OG_ATL||ATL,fib4236]}
                values={price_data}
                setPrice={setPrice}
                />}
            </div>

        </Box>

    )
}

export default CustomeChart;