import * as React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, TextField, Typography,Modal } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import CustomButton from "../../../component/Buttons/CustomButton";
import PrivacyPolicyText from "../../../component/PrivacyPolicyText";
import {sendCode, verifyCodeAndUpdate} from 'api';
import { set_message } from "redux/reducer/message";
import axios from "axios";
import { set_user,set_plan } from "redux/reducer/user";
import { Close } from "@mui/icons-material";

const OTPModal=({type,visible,username,setModalData})=>{
    const [otp,setOtp]=React.useState("");
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [loading,setLoading]=React.useState(false);
    const [counter,setCount]=React.useState(0);
    const ALPHA_NUMERIC_DASH_REGEX = /^[0-9-]+$/;
    const ref1=React.useRef();
    const ref2=React.useRef();
    const ref3=React.useRef();
    const ref4=React.useRef();
    const ref5=React.useRef();
    const ref6=React.useRef();
    const refArry=[ref1,ref2,ref3,ref4,ref5,ref6];
    React.useEffect(()=>{
      ref1?.current?.focus();
    },[visible,navigate])
    
   const startTimer= React.useCallback(()=>{
      setCount(30);
      let interval=setInterval(()=>{
        setCount(prev=>{
          if(prev==0){
            return clearInterval(interval);
          }
          return prev-1;
        })
      },1000)
    })

    const handleChange=(e)=>{
        if(e.key==="Backspace"){
            if(e.target.name==="1"){
                setOtp("")
                return
            }
            if(otp[e.target.name-1]!==undefined){
              setOtp(prev=>prev.slice(0,-1))
            }
            refArry[e.target.name-2].current.focus();
        }else{
          
            if(e.target.name==="6"){
                if(otp[5]){
                    let new_otp=otp.slice(0,-1)+e.key.replace(otp[5],"")
                    setOtp(new_otp)
                }else{
                    setOtp(prev=>prev+e.key);
                }
               
            }else{
                setOtp(prev=>prev+ e.key.replace(otp[e.target.name-1],""));
                refArry[parseInt(e.target.name)].current.focus();
            }            
        }
    }

    const handleVerify=React.useCallback(()=>{
      if(loading)return;
      setLoading(true)
      verifyCodeAndUpdate(otp,type)
      .then((mainRes)=>{
            axios.get('/api/v1/user/profile')
            .then(res => {
                dispatch(set_user(res?.data?.user))
                dispatch(set_plan(res?.data?.plan))
                mainRes?.data?.msg&& dispatch(set_message(mainRes.data.msg))
                handleClose();
            })
            .catch(() => {
                dispatch(set_user(null))
            })
       

      })
      .catch(err=>{
        console.log(err)
        if(err.response.status<500 && err.response.status>=400){
          dispatch(set_message(err.response.data.msg))
        }else{
          dispatch(set_message('something went wrong !'))
        }
        setLoading(false)
      })
    },[username,otp,setLoading,dispatch,navigate])
    
    React.useEffect(()=>{
      if(otp.length===6){
        handleVerify();
      }
    },[otp,handleVerify])

    const handleKeypress=event=>{
      if (event.key!=='Backspace' && !ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
        event.preventDefault();
      }else{
        handleChange(event)
      }
    }


    const resend_code=()=>{
      if(counter)return;
      sendCode(username)
      .then(()=>{
        startTimer();
        dispatch(set_message('we have sent otp to '+username))
      })
      .catch((err)=>dispatch(set_message(err.response.data.msg)))
    }

    const handleClose=()=>{
      setModalData({visible:false,type:null,username:null})
      setOtp('')
      setLoading(false)
    }
   
  return (
    <Modal
           sx={{
            display:visible?'flex':'none',
           alignItems:'center',
           bgcolor:'#000000bd'
           }}
            keepMounted
            open={true}
            onClose={()=>{}}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
    <>
    <Box
      component="form"
      sx={{
        maxWidth: 450,
        width: "92%",
        bgcolor:'#fff',
        padding:'30px 15px',
        borderRadius:5,
        position:'relative'
      }}
      mx="auto"
      autoComplete="off"
    >
        <Button onClick={handleClose} sx={{position:'absolute',top:10,right:0}}><Close sx={{color:'#000',fontSize:'30px'}}/> </Button>
      <Box
        display="flex"
        sx={{ height: "15vh", justifyContent: "center", alignItems: "center", marginTop: '2%' }}
      >
        <img src={logo} height="150px" alt="logo" />
      </Box>
      <Typography mt={3}  variant="h6">
      Enter verification code {type=='phone'?'Phone Number':'Email'}
      </Typography>
      <Box sx={{display:'flex'}} mt={2}>
        <TextField onKeyDown={handleKeypress} type="number" inputMode="numeric" name="1" value={otp[0]||""}  inputRef={ref1} inputProps={{style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField onKeyDown={handleKeypress} type="number" inputMode="numeric" name="2" value={otp[1]||""}  inputRef={ref2} inputProps={{style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField onKeyDown={handleKeypress} type="number" inputMode="numeric" name="3" value={otp[2]||""}  inputRef={ref3} inputProps={{style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField onKeyDown={handleKeypress} type="number" inputMode="numeric" name="4" value={otp[3]||""}  inputRef={ref4} inputProps={{style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField onKeyDown={handleKeypress} type="number" inputMode="numeric" name="5" value={otp[4]||""}  inputRef={ref5} inputProps={{style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
        <TextField onKeyDown={handleKeypress} type="number" inputMode="numeric" name="6" value={otp[5]||""}  inputRef={ref6} inputProps={{style:{fontWeight:700,maxLength:1,textAlign:'center'}}} sx={{mx:1,fontSize:20,}}/>
      </Box> 
      <Box my={3} textAlign="center">
        <Typography variant="p" >
          Didn't receive a CODE? 
          {counter?<Typography >Please retry in {counter}s</Typography>:
          <Button onClick={resend_code} style={{ color: "#000",cursor:'pointer' }} >
          Resend
       </Button>
          }
          
        </Typography>
      </Box>
      <CustomButton loading={loading} onClick={handleVerify} text="Verify" />

    </Box>
    </>
    </Modal>
  );

}


export default OTPModal;