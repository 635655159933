//import { Copyright, FacebookRounded, Instagram, Twitter,Tik } from "@mui/icons-material";
import { Typography,Box} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from 'assets/logo.svg'
import Facebook from 'assets/social/sc_fb.png'
import TikTok from 'assets/social/sc_tt.png'
import Twitter from 'assets/social/sc_tw.png'
import Instagram from 'assets/social/sc_in.png'
import Youtube from 'assets/social/sc_yt.png'
import Linkedin from 'assets/social/sc_li.png'
const Footer=()=>{
return(<Box sx={{   
    backgroundColor:'#21232E',
    color:'#fff',
    pb:2}}>
    <Box sx={{maxWidth:'100%',width:'max-content',margin:'auto'}}><img height={70} src={Logo}/></Box>
    <Box sx={{maxWidth:'100%',width:'max-content',margin:'10px auto',display:'flex',justifyContent:'space-evenly',flexWrap:'wrap',textAlign:'center'}}>
        <Link to='/privacy' style={{color:'#fff',textDecoration:'none',margin:'5px 15px'}}>Privacy Policy</Link>
        <Link to='/term-of-service' style={{color:'#fff',textDecoration:'none',margin:'5px 15px'}}>Terms of Service</Link>
        <Link to='/disclaimer' style={{color:'#fff',textDecoration:'none',margin:'5px 15px'}}>Disclaimer</Link>
    </Box>
    <Box sx={{maxWidth:'100%',width:'max-content',margin:'20px auto'}}>
        {/* <FacebookRounded sx={{color:'#fff',margin:'2px 20px',fontSize:25}}/>
        <Instagram       sx={{color:'#fff',margin:'2px 20px',fontSize:25}}/>
        <Twitter         sx={{color:'#fff',margin:'2px 20px',fontSize:25}}/> */}
        {/* <Link to={''}><Box component={'img'} sx={{width:40,mx:1}} src={TikTok}/></Link>
        <Link to={''}><Box component={'img'} sx={{width:40,mx:1}} src={Twitter}/></Link>
        <Link to={''}><Box component={'img'} sx={{width:40,mx:1}} src={Instagram}/></Link>
        <Link to={''}><Box component={'img'} sx={{width:40,mx:1}} src={Facebook}/></Link>
        <Link to={''}><Box component={'img'} sx={{width:40,mx:1}} src={Youtube}/></Link>
        <Link to={''}><Box component={'img'} sx={{width:40,mx:1}} src={Linkedin}/></Link> */}
        {/* <Tik         sx={{color:'#000',margin:'2px 20px'}}/> */}
    </Box>
    <Box sx={{mt:2}}>
    <Typography sx={{textAlign:'center',fontSize:12,fontWeight:'500'}}>
       &copy;  2022 - {new Date().getFullYear()} XGain LLC. XGain is provided for informational purposes only. It is not offered or intended to be used as legal, tax, investment, financial, or any other advice.
    </Typography>
    </Box>
</Box>)
}

export default Footer;