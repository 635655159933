import React, { useState } from 'react';
import { TextField,InputAdornment,MenuItem } from '@mui/material';
// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import MenuItem from '@material-ui/core/MenuItem';


function PhoneNumberInputLogin({data,name,setData,handleSubmit,id,value,onBlur,onChange,error,helperText,formik}) {
  
  const [countryCode, setCountryCode] = useState(data?.country_code||'+1');

  const handleCountryCodeChange = (event) => {
    formik.setFieldValue('country_code', event.target.value);
    setCountryCode(event.target.value);  
   };



  return (
      <TextField
  
      id={id}
      fullWidth
      type='number'
      name={name}
      required={true}
      placeholder={'Phone Number'}
      sx={{ my: "5px" }}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
      error={error}
      inputProps={{inputMode: 'numeric', style: { fontSize: 18 } }} // font size of input text

        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <TextField
                select
                value={countryCode}
                onChange={handleCountryCodeChange}
                variant="standard"
              >
                <MenuItem value="+1">+1</MenuItem>
                <MenuItem value="+44">+44</MenuItem>
                <MenuItem value="+86">+86</MenuItem>
                <MenuItem value="+91">+91</MenuItem>
                <MenuItem value="+55">+55</MenuItem>
                <MenuItem value="+81">+81</MenuItem>
                <MenuItem value="+90">+90</MenuItem>
                <MenuItem value="+48">+48</MenuItem>
                <MenuItem value="+420">+420</MenuItem>
                <MenuItem value="+351">+351</MenuItem>
                <MenuItem value="+34">+34</MenuItem>
              </TextField>
            </InputAdornment>
          ),
        }}
      />
  
  );
}

export default PhoneNumberInputLogin;