import * as React from 'react';
import {
  GetRailProps,
  GetHandleProps,
  GetTrackProps,
  SliderItem,
} from 'react-compound-slider';
import FormateCurrency from 'utils/FormateCurrency';

const railOuterStyle = {
  position: 'absolute',
  height: '100%',
  width: 42,
  transform: 'translate(-50%, 0%)',
  borderRadius: 3,
  cursor: 'pointer',
};

const railInnerStyle = {
  position: 'absolute',
  height: '100%',
  width: 0,
  transform: 'translate(-50%, 0%)',
  borderRadius: 3,
  pointerEvents: 'none',
  backgroundColor: 'White', //slider track bg
};



export const SliderRail = ({ getRailProps }) => {
  return (
    <>
      <div style={railOuterStyle} {...getRailProps()} />
      <div style={railInnerStyle} />
    </>
  );
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************

export const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
  hideSliderNumber,
  width,
  height,
}) => {
  return (
    <>


      <div
        style={{
          top: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 5,
          width: 40,
          height: 28,
          cursor: 'pointer',
        }}
        {...getHandleProps(id)}
      >
     {!hideSliderNumber?<p style={{width:"max-content", color:"#fff",background:"none",position:"relative",left:35,bottom:-5,fontSize:12}}>{FormateCurrency(value)}</p>:<></>} 
</div>      
<div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          top: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
          width: width|| 10,
          height:height||10,
          marginBottom:-10,
          borderRadius: '50%',
          boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
          backgroundColor: '#95F204',
          
        }}
      />
    </>
  );
};

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export const KeyboardHandle = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) => {
  return (
    <button
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        top: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: 24,
        height: 24,
        zIndex: 5,
        cursor: 'pointer',
        border: 0,
        borderRadius: '50%',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#D7897E',
      }}
      {...getHandleProps(id)}
    />
  );
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************

export const Track= ({
  source,
  target,
  getTrackProps,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1,
        backgroundColor: '#95F204',
        borderRadius: 7,
        cursor: 'pointer',
        width: 0,
        transform: 'translate(-50%, 0%)',
        top: `${source.percent}%`,
        height: `${target.percent - source.percent}%`,
       
      }}
      {...getTrackProps()}
    />
  );
};

// *******************************************************
// TICK COMPONENT
// *******************************************************


export const Tick= ({ tick, format = d => d }) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: -0.5,
          marginLeft: -15,
          height: 1,
          width: 6,
          color:'blue',
          backgroundColor: 'rgb(200,200,200)',
          top: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: -8,
          marginLeft: -55,
          fontSize: 10,
          top: `${tick.percent}%`,
          color:"#fff"
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
};