import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { set_message } from 'redux/reducer/message';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

export default function ToastContainer() {
 const error=useSelector(state=>state.error.error);
 const theme=useTheme();
 const isMobile=useMediaQuery(theme.breakpoints.down('md'))
 const dispatch=useDispatch();
  const handleClose = (event, reason) => {
    event?.preventDefault();
    dispatch(set_message(null));
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
        {error && 
        <Snackbar
        key={Math.random()}
        open={true}
        anchorOrigin={{ vertical:isMobile?'top':'bottom', horizontal:isMobile?'center':'left' }}
        autoHideDuration={6000}
        onClose={handleClose}
        message={error}
        action={action}
      />}
      
    </div>
  );
}