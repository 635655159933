const FormateText=(text)=>{
switch(text){
    case "ADD MORE COIN":{
        return 'Added More'
    }
    case "ADD NEW COIN":
        return 'Added New'
    
    case "DELETE_COIN":{
        return 'Deleted'
    }
    case "EDIT COIN":{
        return 'Updated'
    }
    case "LOGIN":
        return "LogIn"
    case "UPDATED_SELL":
        return "XGain Update"
    
        case "EXPORT_EXCEL":
        return "Exported to CSV"
        
    default:
        return "N/A"

}
}


export default FormateText;