//const FormatCurrency=(price=0)=>{
const FormateCurrency=(price=0)=>{
    if(price===undefined)return
    if(price<1 && price>8e-10){
        price=(0.00001<price?price.toFixed(5):price.toFixed(7))
        return `$${price}`
    }
    return price.toLocaleString('en-US',{
        style:'currency',
        currency:'USD',
    })
}

export default FormateCurrency;