import { Typography,Box } from "@mui/material";
import React from 'react'
export const Disclaimer=()=>{
    React.useEffect(()=>{
        document.title='XGain | Disclaimer'
      },[])
return(
    <Box sx={{width:'96%',margin:'auto',maxWidth:'1200px',paddingTop:5,textAlign:'justify',px:1}}>
        <Typography  variant="h3">Disclaimer</Typography><br/>
        <Typography variant="body">
        
        </Typography> <br/><br/>
        <Typography variant="body">
        <h2>READ THIS DISCLAIMER CAREFULLY BEFORE USING XGAIN</h2> <br/><br/><br/>
        

By using any part of this site and the information provided, you acknowledge and agree to 
<br/><br/>
<h2>No Financial Advice:</h2>
The information and tools provided <b>XGain</b> are for general informational purposes only and should not be construed as financial or investment advice. We do not provide personalized investment recommendations, and any investment decisions made based on the information provided are in complete <b>at your own risk</b>.
<br/><br/>
<h2>Risk of Loss:</h2>
Cryptocurrency investments carry a high level of risk and may result in the loss of your entire investment. The value of cryptocurrencies can be highly volatile and unpredictable. Past performance is not indicative of future results, and <b>there is no guarantee of positive returns</b>.
<br/><br/>
<h2>User Responsibility:</h2>
You acknowledge and agree that <b>you are solely responsible</b> for <b>conducting your own research</b> and due diligence before making any investment decisions. Our crypto investment tool portal is not responsible for any losses or damages incurred as a result of your investment choices.
<br/><br/>
<h2>Technical Limitations:</h2>
While we strive to provide accurate and up-to-date information, <b>XGain</b> may experience technical issues, data inaccuracies, or delays. We do not guarantee the availability, reliability, or accuracy of the tools and information provided, and we shall not be held liable for any losses or damages arising from such issues.
<br/><br/>

<h2>Accessibility Disclaimer:</h2>
We strive to provide an inclusive and accessible platform to all users. However, we acknowledge that accessibility requirements may vary, and despite our best efforts, there may be limitations or challenges that some users might encounter.
<br/><br/>
<b>We strongly advise users with any sorts of disabilities to be aware of the following:</b>

<br/>Accessibility Limitations: While we make continuous efforts to enhance the accessibility features of our portal, we cannot guarantee full accessibility for individuals with specific disabilities or unique accessibility needs. Our platform may not be fully compatible with all assistive technologies or may present challenges for users with certain impairments.

<br/>Third-Party Integrations: Our portal may contain links to third-party websites, tools, or services that are beyond our control. We cannot guarantee the accessibility compliance of these external resources, and users should exercise caution and refer to the accessibility policies of those third parties when utilizing their services.

<br/>Individual User Settings: Our platform provides certain customization options to enhance user experience, including font size adjustments, color contrasts, and keyboard navigation. However, it is the user's responsibility to make use of these settings according to their individual preferences and accessibility needs.

<br/>Continuous Improvement: We are committed to continually improving the accessibility of our platform based on user feedback and technological advancements. However, please note that updates and improvements may take time to implement, and certain accessibility features may not be immediately available.
<br/><br/>
It is important to understand that while we strive to provide an inclusive user experience, individual accessibility needs may vary. We encourage users to reach out to us with specific concerns, suggestions, or accessibility requirements, as we value the feedback and aim to address accessibility concerns to the best of our abilities.
<br/><br/>
By using XGain, users acknowledge and agree that our accessibility efforts may have limitations and that we shall not be held liable for any difficulties, inconveniences, or adverse effects experienced while using our platform due to accessibility-related issues.

<br/><br/>

<h2>Third-Party Content:</h2>
<b>XGain</b> may contain links or references to third-party websites, tools, or resources. These links are provided for your convenience, and we do not endorse or assume any responsibility for the content, accuracy, or reliability of any third-party resources.
<br/><br/>
<h2>Regulatory Compliance:</h2>
Cryptocurrency investments are subject to regulatory frameworks and legal requirements that may vary by jurisdiction. It is your responsibility to ensure compliance with applicable laws, regulations, and tax obligations in your jurisdiction before engaging in any investment activities.
<br/><br/>
<h2>No Endorsement or Partnership:</h2>
Mentions of specific cryptocurrencies, exchanges, or services on our crypto investment tool portal do not constitute an endorsement or partnership. We do not guarantee the performance, security, or reliability of any third-party products or services mentioned.
<br/><br/>
<h2>Disclaimer of Liability:</h2>
To the maximum extent permitted by law, <b>XGain</b>, its owners, operators, employees, and affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the portal, reliance on the provided information, or any investment decisions made based on such information.
<br/><br/>
<h2>Personal Security:</h2>
Protecting your personal and financial information is of utmost importance. We take reasonable security measures, but we cannot guarantee the complete security of your data. You are responsible for safeguarding your personal information and taking appropriate precautions when using <b>XGain</b>.
<br/><br/>
<h2>Consultation with Professionals:</h2>
We strongly recommend consulting with qualified financial advisors, tax professionals, and legal experts before making any investment decisions. The information provided on <b>XGain</b> should not replace professional advice tailored to your specific financial situation and investment goals.
<br/><br/>

<h2>Use of Language Translators by Users:</h2>
<br/><b>We strongly advise users</b> against relying on language translation services to interpret the content provided on XGain. While these services may offer convenience, accuracy, reliability, and security cannot be guaranteed.
<br/>If users choose to utilize any translation services to translate the information on our portal into their preferred language, they do so at their own risk and discretion. It is important to note that translations generated by third-party tools may be flawed, incomplete, or contain errors, which can lead to misunderstandings or misinterpretations.
<br/>We strongly recommend users to exercise caution and consider the limitations of third-party translation services. It is always advisable to consult with certified translators or language experts for accurate and reliable translations before making any investment decisions or taking action based on the information provided.
<br/>We shall not be held responsible for any losses, damages, disputes, or negative consequences resulting from the use of third-party translation services by users or the reliance on translated content. Users bear the sole responsibility for any risks associated with using third-party translators.
<br/><br/><br/><br/>


</Typography>
    </Box>
)
}


export default Disclaimer;