import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'grey',
        boxShadow:'0px 0px 3px #fff'
      },
    },
  });


// this component is use to accept number only;
const CustomInputDark = ({ label,color,sx,disabled,textFontSize=16,labelFontSize=14,focused,placeHolder,name, value,type,onChange,optional,autoComplete,defaultValue,startAdornment,...props }) => {
  const [localValue,setLocalValue]=useState(value);
  const handleInputChange = (event) => {
    // Check if the input value is a number with optional decimal using a regular expression
    if (/^-?\d*\.?\d*$/.test(event.target.value)) {
      onChange(event);
      setLocalValue(event.target.value);
    }
  };
  
  useEffect(()=>setLocalValue(value),[value])
  return (
    <CssTextField
      color={color}
      required={optional?false:true}
      label={label}
      focused={focused}
      fullWidth
      placeholder={placeHolder}
      value={localValue}
      name={name}
      type="number"
      disabled={disabled}
      onChange={handleInputChange}
      sx={{ my: "5px",bgcolor:'#21232D',...sx}}
      // inputProps: {
      //   inputMode: 'numeric', // Set the input mode to 'numeric'
      // },
      InputProps={{ sx: { height: 50, fontSize: 10 } ,startAdornment:startAdornment,endAdornment:props.endAdornment}}
      inputProps={{ step: '0.000000000001', // Allow decimal input
      inputMode: 'decimal', // Prompt numeric keyboard
      pattern: '[0-9]*', 
      style: { fontSize: textFontSize,color:"#fff", } }} // font size of input text
      InputLabelProps={{ style: { fontSize: labelFontSize,color:'#fff'} }} // font size of input label
      autoComplete={autoComplete}
      defaultValue={defaultValue}
    />
  );
};

export default CustomInputDark;

