import { Box,Typography } from "@mui/material"
import Section from "../../components/Section"
import PortfolioSS from 'assets/xcxg-assets.png'
import RedOutlineButton from "../../components/RedOutlineButton"
import BigX from 'assets/bigx.png'
import LogoImg from 'assets/xgainTM.png'
import { useState,useEffect } from "react"
const AssetsSection=()=>{
  const [data,setData]=useState({title:"",description:""});
  useEffect(()=>{
    fetch('/data/screen-2.json')
    .then(res=>res.json())
    .then(setData)
  },[])

  return(<Section sx={{bgcolor:'black',borderBottom:'1px solid #fff',display:{xs:'block',md:'flex'},overflow:'hidden',position:'relative'}}>
    
  <Box sx={{
    zIndex:99,
    position:'relative',
    width:'90%',
    margin:'auto',
    maxWidth:'1600px',
    marginTop:10,
    textAlign:'center',
    }}>
    <Typography  sx={{textAlign:'center'}}>
    <img height={50} src={LogoImg} alt='logo' />
    </Typography>
    <Typography  sx={{color:'#fff',fontSize:25,fontWeight:'bold',textAlign:'center'}}>{data.title}</Typography>
    <Box
       sx={{width:'98%',display:'block',margin:'auto',marginTop:5,maxWidth:'900px'}}
       component={'img'}
       src={PortfolioSS}
      />
    <Typography  sx={{color:'#fff',marginTop:{xs:5,md:1,},textAlign:{xs:'center',md:'left'},display:{xs:'block',md:'inline-block'}}}>
    {data.description}
    </Typography>
    <Box sx={{display:'flex',justifyContent:{xs:'center'}}}>
    <RedOutlineButton sx={{marginTop:10,}}/>
    </Box>
  </Box>
  <Box 
  sx={{
      position:'absolute',
      zIndex:0,
      maxWidth:500,
      left:0,
      right:0,
      margin:'auto',
      bottom:{xs:"-26%",md:'-25%'},
  }}
  component={'img'} 
  src={BigX}
  />
  </Section>)
}

export default AssetsSection;