import { Button } from "@mui/material"
const CategoryButton=({text,onClick,selected})=>(
    <Button  variant="contained" sx={{
        bgcolor:selected  && '#7E8DB8',
        cursor:'pointer',
       ":hover":{
        background:selected  && '#7E8DB8',
       },
       textTransform:'capitalize',
        margin:'10px 5px',
        marginBottom:0.5,
        padding:'5px 15px',
        border:'1px solid grey',borderRadius:10}} onClick={onClick}>{text}</Button>
) 


export default CategoryButton;
