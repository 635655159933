import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import CustomInput from "../../../component/Input/CustomInput";
import CustomButton from "../../../component/Buttons/CustomButton";
import PrivacyPolicyText from "../../../component/PrivacyPolicyText";
import { set_message } from "redux/reducer/message";
import { sendCode } from "api";
import { useDispatch } from "react-redux";
import PhoneNumberInputLogin from "component/Input/PhoneInputLogin";
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import Section from "../home/components/Section";
import { useSelector } from "react-redux";

const validationSchema = yup.object({
  phone: yup.string().max(10,'Maximum 10 digits').min(10,'Min 10 digits').matches(/^[0-9]+$/, 'Numbers only'),
  email:yup.string().email('Invalid email format').max(50,'Max 50 characters')
});



export const Login = () => {
  React.useEffect(()=>{
    document.title='XGain | Login'
    localStorage.clear();
  },[])
  const [username, setUsername] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [active, setActive] = React.useState("phone");
  const [captcha,setCaptcha]=React.useState(null);
  const error=useSelector(state=>state.error.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      country_code: '+1',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(active=='phone' && !values.phone){
        formik.setFieldError('phone',' required*')
        return;
      }else if(active=='email' && !values.email){
        formik.setFieldError('email',' required*')
        return
      }
      let username=active=='phone'?values.country_code+' '+values.phone:values.email
      sendCode(username,captcha)
      .then(() => {
        // dispatch(set_message(`CODE sent to ${username}`))
        navigate('/login/verify', { state: { username: username } })
      })
      .catch((err) => {
        if (err.response.status < 500 && err.response.status >= 400) {
          dispatch(set_message(err.response.data.msg))
        } else {
          dispatch(set_message('Ooops! Something went wrong!'))
        }
        setLoading(false)
      })
    },
  });

  const handleChange = (e) => setUsername(e.target.value);
 
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if(!username)return;
  //   if (active=='email') {
  //     var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  //     if (!username.match(pattern)) {
  //       dispatch(set_message("Invalid email address"))
  //       return;
  //     }
  //   } else {
  //     let pattern = /^\+\d{1,3}\s\d{10}$/;
  //     if (!username.match(pattern)) {
  //       return dispatch(set_message("Invalid phone number!"));
  //     }

  //   }
  //   setLoading(true)
  //   sendCode(username)
  //     .then(() => {
  //       dispatch(set_message(`CODE sent to ${username}`))
  //       navigate('/login/verify', { state: { username: username } })
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       if (err.response.status < 500 && err.response.status >= 400) {
  //         console.log(err)
  //         dispatch(set_message(err.response.data.msg))
  //       } else {
  //         dispatch(set_message('Ooops! Something went wrong!'))
  //       }
  //       setLoading(false)
  //     })
  // }

  React.useEffect(()=>{
    let element=document.querySelector('#phone');
    if(element){
      element.focus();
    }
  },[])

  const getSyle = (type) => {
    if (type === active) {
      return {':hover':{
        bgcolor: "primary.main", color: '#fff'
      }, bgcolor: "primary.main", color: '#fff', width: '50%', px: 3 }
    }
    return { width: '50%', px: 3 }
  }



  return (
    <Section>
 <Box
      component="form"
      sx={{
        maxWidth: 400,
        width: "92%",
      }}
      mx="auto"
      autoComplete="off"
      onSubmit={formik.handleSubmit}

    >
      <Box
        display="flex"
        sx={{ height: "15vh",minHeight:'130px', justifyContent: "center", alignItems: "center", marginTop: '30%' }}
      >
        <img src={logo} height="150px" alt="logo" />
      </Box>

      <Typography mt={3} sx={{textAlign:'center'}} variant="h6">
        How should we send your CODE?
      </Typography>
      
      <Box sx={{ border: '1px solid black', width: 'max-content', margin: '20px auto', overflow: 'hidden', borderRadius: 10 }}>
        <Button onClick={() => setActive('phone')} sx={getSyle('phone')}>Text</Button>
        <Button onClick={() => setActive('email')} sx={getSyle('email')}>Email</Button>
      </Box>
      
      <Box mt={2}>
        <Typography sx={{color:"red",textAlign:'center'}}>{error}</Typography>
        {active === 'phone' ? 
        <PhoneNumberInputLogin 
        id='phone' 
        name='phone'
        value={formik.values.phone}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur} 
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        formik={formik}
        handleSubmit={formik.handleSubmit} 

         /> :
          <CustomInput
           type='email' 
           label="Email" 
           name='email'
           onChange={formik.handleChange}
           onBlur={formik.handleBlur}
           error={formik.touched.email && Boolean(formik.errors.email)}
           helperText={formik.touched.email && formik.errors.email}
           
           />
        }
      </Box>
      <Box my={3} textAlign="center">
        <Typography variant="p" color="grey">
          Don't have an account? 
          <Link to="/register" style={{ marginLeft:4,color:'#1976d2',textDecoration:'none' }} variant="primary">
             Join BETA here
          </Link>
        </Typography>
      </Box>
      
      <Box sx={{mx:'auto',display:'flex',justifyContent:'center',mb:4}}>
      <ReCAPTCHA
       sitekey="6Ld8rWknAAAAAD_jifcFUozxmQK79tSd4J4V9C2p"
       onChange={setCaptcha}
     />
      </Box>
     
     {captcha &&  <CustomButton  loading={loading} onClick={formik.handleSubmit} text="BETA Login" />}
      <PrivacyPolicyText />
    </Box>
    </Section>

  );
};

export default Login;
