import { useEffect, useState } from "react";
import AddWallet from "component/Modal/AddWallet/AddWallet";
import {Box,Button} from "@mui/material"
import { getWallet, updateWallet } from "api/crypto";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExchangeIcon from "component/Exchanges/ExcangeIcon";

const ExchangeandWallet=({id,symbol,wallet})=>{
    const [showWallet,setShowWallet]=useState(false);
    const [data,setData]=useState({wallet:wallet||[],exchange:[]});


    useEffect(()=>{
        if(!id)return;
        if(wallet)return;
        getWallet(id)
        .then(res=>{
            let {wallet,exchange}=res.data;
            try{
                wallet=JSON.parse(wallet);
            }catch{
                wallet=null;
            }

            try{
                exchange=JSON.parse(exchange);
            }
            catch{
                exchange=null;
            }

            setData({wallet:wallet||[],exchange:exchange||[]})
        })
    },[id])
    
   

    const toggleWallet=()=>{
        setShowWallet(!showWallet);
    }

    const setWallet=(wallets)=>{
        updateWallet(id,wallets)
        .then(()=>{
            setData({...data,wallet:wallets})
            toggleWallet();
            
        })
        .catch(()=>{
            
        })
    }


    return(
        <Box sx={{display:'flex',alignItems:'center',mt:0}}>
            {showWallet && <AddWallet
            id={id}
            symbol={symbol}
            toggleWallet={toggleWallet}
            wallet={data.wallet||[]}
            setWallet={setWallet}
            />}
            <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                {data.wallet.map(name=>(<ExchangeIcon size={20} name={name}/>))}
            </Box>
            <Button id={`exchange-${id}`} sx={{ minWidth: 0,opacity:0,w:0,h:0,fontSize: 20 }}  onClick={toggleWallet}>
                <AddCircleOutlineIcon sx={{color:"#fff"}} />
            </Button>
        </Box>
    )
}

export default ExchangeandWallet;