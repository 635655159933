import { Typography } from "@mui/material"
const FormateDeltaATH = (value) => {
    return {
        element: 
            <Typography fontWeight='bold' sx={{ margin: 0, padding: 0, width: '100%'}}>
               {value==undefined?"NA":value.toFixed(1) + '%'}
            </Typography>
        ,
        sx:{background:value < 0 ? 'rgb(187, 68, 68)' : 'rgb(36, 136, 34)' }
    }
}

export default FormateDeltaATH;