import { Check } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import LogoText from "component/Logo";
import LogoImg from 'assets/xgainTM.png'
import { Box } from "@mui/material";
import RedOutlineButton from "../../components/RedOutlineButton";
import { buyPlanApi } from "api/payment";
const PriceCard = ({id, name, amount, features = [] }) => {
    
    const buyPlan=()=>{
        buyPlanApi(id)
        .then(res=>{
            window.location.href=res.data.url
        })
        .catch()
    }
    return (<Box sx={{
        bgcolor: '#0F111D',
        color: '#fff',
        width: "max-content",
        maxWidth: '400px',
        margin: 5,
        paddingTop: 5,
        paddingX: 4,
        borderRadius: 3,
        minHeight: 600,
        boxShadow: '0px 0px  2px #fff'
    }}>
        <Typography sx={{ fontFamily: 'ethnocentric', textAlign: 'center' }}>
            <Box component={'img'} src={LogoImg}/>
        </Typography>
        <Typography sx={{ marginY: 2, textAlign: 'center', fontSize: 15 }}>{name}</Typography>
        <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                <Typography sx={{ fontSize: 30, fontWeight: 500, marginRight: 0.5 }}>${amount} </Typography>
                <Typography sx={{ fontSize: 14 }}> / month</Typography>
            </Box>
            <Typography sx={{ fontSize: 14 }} marginTop={-1}>Billed yearly</Typography>
        </Box>
        {/* <Button sx={{bgcolor:'#2F3653',color:'#fff',marginX:'auto',display:'block',marginTop:4,paddingX:11,paddingY:1.1,borderRadius:2}}>
        Subscribe
    </Button> */}
        <RedOutlineButton onClick={buyPlan} text='Subscribe' sx={{ marginX: 'auto', display: 'block', marginTop: 4, paddingX: 11, paddingY: 1.1 }} />

        {/* <Typography sx={{textAlign:'center',marginTop:1.5,lineHeight:1.3,fontSize:12}}>
        7 Day Free Trial<br/>
         Upgrade as you grow
    </Typography> */}
        <Box sx={{ marginTop: 6 }}>
            {features.map(text => (
                <Typography sx={{ display: 'flex', marginTop: 2.5, fontSize: 15 }}>
                    <Check sx={{ color: '#6CFF26', marginRight: 1.4, fontSize: 22 }} />
                    {text}
                </Typography>
            ))}



        </Box>
    </Box>)
}

export default PriceCard;