import { Button,Box} from "@mui/material"
import { Link } from "react-router-dom";
const RedOutlineButton=({sx,text,onClick})=>{
    return(
        <Box sx={{display:{xs:'flex',md:'block'},justifyContent:'center'}}>
            <Link to={'/register'} style={{ textDecoration: 'none', }}>
                <Button onClick={onClick} sx={{
                    borderRadius: 10,
                    background: '#000',
                    color: '#fff',
                    py: 1,
                    px: 4,
                    fontSize: '1.2rem',
                    border: '2px solid red',
                    textTransform: 'none',
                    ...sx
                }} variant="outlined">
                    {text || 'Join Beta'}
                </Button>
            </Link>
        </Box>
    )
}

export default RedOutlineButton;