import { Card } from "@mui/material";
import React from "react";
import Chart from 'react-apexcharts'
import FormateCurrentcy from "../../utils/FormateCurrency";
class Treemap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       series: [{data:this.props.label.map((obj,index)=>{return {x:this.props.label[index],y:( this.props.series[index])}}).filter(obj=>obj.y!==0)}],
      
        option:{ 
          
        legend: {
            show: false
          },
          chart: {
            sparkline:{enabled:true},
            type: 'treemap',
            foreColor: '#fff',
            
            events: {
              // mounted: (chart) => {
              //   chart.windowResizeHandler();
              // }
            }
          },
          tooltip: {
            style: {
              fontSize: '12px',
              color:'#000',
            },
            y: {
                formatter: function (val) {
                    return FormateCurrentcy(val)
                }
            }
        },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
            
            },
            
            formatter: function(text, op) {
             
            return [text, FormateCurrentcy(op.value)]
             
            },
           
          },
          plotOptions: {
            treemap: {
              enableShades: true,
              shadeIntensity: 0.2,
              reverseNegativeShade: true,
              colorScale: {
                ranges: [
                  {
                    from:-10000000,
                    to: 100000000,
                    color: '#CD363A'
                  },
                  {
                    from:0,
                    to: 1000000000,
                    color: '#52B12C'
                  }
                ]
              }
            }
          }
          }}
}


  render() {
    return (
      <Card elevation={0} sx={{ bgcolor: 'primary.light', minHeight: {xs:250,sm:'100%'}, padding: { xs: 1, md: 1 }, boxShadow: '0px 0px 2px #fff', width: '100%', height: '100%' }}>
        
        <Chart id='treemap'
          height='100%'
          series={this.state.series}
          options={this.state.option}
          type='treemap'

        />
      </Card>


    );
  }
}
export default Treemap;

