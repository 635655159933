import { Box, Typography } from "@mui/material"
import { getPlan } from "api/payment";
import PriceCard from "pages/public/home/sections/pricing/price-card";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const PayNow = () => {
    const {plan}=useSelector(state=>state.user)
    const [newPlan,setNewPlan]=useState([]);
    console.log({plan})
    useEffect(()=>{

        if(!plan)return 
        getPlan()
        .then(res=>{
            let data=res.data;
            if(plan.previous_plan.name=='Pro'){
                return setNewPlan(data.plans.filter(obj=>obj.name.includes('Pro')))
            }
            setNewPlan(data.plans)
        })
    },[plan])

    return (
        <Box sx={{ height: '80vh', width: '100%', display: 'grid', placeContent: 'center' ,textAlign:'center'}}>
            <Typography sx={{ color: '#fff' }} variant="h5" marginTop={20}>Your {plan?.previous_plan?.description} Plan Has Expired!</Typography>
            <Box sx={{display:'flex'}}>
                {newPlan.map(obj=><PriceCard id={obj._id} amount={obj.amount} features={obj.features} name={`${obj.description} Plan`}/>)}
            </Box>

        </Box>



    )
}


export default PayNow;