import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
export const TableCategoryOverview=({headers,rows})=> {
  return (
    <>
    {/* <Typography variant='h5' sx={{color:'#fff',textAlign:'center',mt:3}}>Category Table</Typography> */}
    <TableContainer elevation={0} style={{margin:"20px auto"}} sx={{ maxWidth:{lg:1000}, bgcolor:'primary.light',color:'common.white', marginTop:1,marginBottom:2}} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map(text=> <TableCell key={text} sx={{color:"common.white",fontSize:18}} align="center">{text}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (
            <TableRow
              key={index}
              sx={{paddingY:2, ' td': { border: 0 }}}
            >
              {row.map((data,index)=><TableCell key={index} sx={{padding:2,color:'common.white'}} align="center">
          
                <Typography style={{ color: '#fff' }}> {data}</Typography>
                </TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}


export default TableCategoryOverview;