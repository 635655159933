import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from 'assets/logo.svg'
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Avatar, Badge } from '@mui/material';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { set_user } from 'redux/reducer/user';
import { useDispatch, useSelector } from 'react-redux';
import { set_message } from 'redux/reducer/message';
import PlanButton from 'component/Buttons/PlanButton';
import { ArrowForward, ArrowForwardIos, Close, Notifications } from '@mui/icons-material';
import LogoText from 'component/Logo';
import LogoImg from 'assets/xgainTM.png'
import { set_unread_notification } from 'redux/reducer/user'; 
import { ExportToCsv } from 'export-to-csv';
import { CSVLink } from 'react-csv';
import { getPlan } from 'api/payment';
import NoCoin from 'component/Modal/NoCoin';
const drawerWidth = 240;
const navItems = ['Merch',];

const ExportCSV=()=>{
  const [data,setData]=React.useState([])
    React.useEffect(()=>{
     fetchData()
    },[])

    const makeLog=()=>{
      axios.post('/api/v1/user/activity',{
        type:'export-to-excel'
      })
    }

    const fetchData=async()=>{
      let res=await axios.get('/api/v1/crypto/my-coin'); 
      setData(res.data.data?.[0]?.assets); 
    }

  const csvHeader=[
   {label:'Rank',key:"cmc_rank"},
   {label:'Name',key:'name'},
   {label:'Symbol',key:'symbol'},
   {label:'Price',key:'price'},
   {label:'Qty',key:'qty'},
   {label:'Ave Buy',key:'avg_buy'},
   {label:'Total Invested',key:'invested'},
   {label:'ATH',key:'ATH'},
   {label:'ATL',key:'OG_ATL'},
   {label:'Market Cap',key:'market_cap'},
  ]
  return (
   <CSVLink
   onClick={makeLog}
   style={{textDecoration:'none',color:'#fff'}}
   data={data}
   headers={csvHeader}
   filename={`xgain-crypto-${new Date()}.csv`}
   >
     Export to CSV
   </CSVLink>
  )
}

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user,plan, p_and_l,unread_notification } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [showHelp,setShowHelp]=React.useState(false);


  const navigate = useNavigate();
  // console.log({p_and_l})
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };



  // const drawer = (
  //   <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
  //     <Typography variant="h6" sx={{ my: 2 }}>
  //       Menu
  //     </Typography>
  //     <Divider />
  //     <List>
  //       {navItems.map((item) => (
  //         <ListItem key={item} disablePadding>
  //           <ListItemButton sx={{ textAlign: 'center' }}>
  //             <ListItemText primary={item} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // ); 



  const [anchorEl, setAnchorEl] = React.useState(null);
  const [plans,setPlan]=React.useState([]);
  const location=useLocation()
  React.useEffect(()=>{
      axios.get('/api/v1/user/unread-notification')
      .then(res=>{
        dispatch(set_unread_notification(res.data.count))
      })
      .catch(err=>console.log(err))
  },[location])

  React.useEffect(()=>{
    getPlan()
    .then(res=>setPlan(res.data?.plans))
    .catch(()=>{})
  },[])

  

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    axios.post('/api/v1/user/logout')
      .then(res => {
        localStorage.clear()
        dispatch(set_user(null))
        navigate('/')
      })
      .catch(err => dispatch(set_message("Ooops! Something went wrong")))

  }



  return (
    <Box sx={{ display: 'flex', marginBottom: 7 }}>
     {showHelp &&  <NoCoin show={showHelp} setShow={setShowHelp}/>} 
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <Box sx={{ padding: {xs:'1px 0px',sm:'1px 5px'}, display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between' }, width: { xs: '100%', md: 'auto' } ,maxWidth:'100v'}}>
            <Link style={{ display: 'flex', textDecoration: 'none', color: '#fff' }} to='/dashboard/main'>
              <Typography
                variant="h6"
                component="div"
                sx={{ alignItems: 'center', display: 'flex' }}
                onClick={() => navigate('/dashboard/main')}
              >
                <Box component='img' sx={{ height: { xs: 50, sm: 50 } }} src={Logo} alt='logo' />
                <Typography sx={{ fontSize: { xs: 26, sm: 30 }, fontWeight: 'bold', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                  {/* Crypto <LogoText fontSizeTm={{xs:12}}/> */}
                  {/* Crypto  */}
                  <Typography component={'span'} sx={{fontSize:'inherit',fontWeight:'bold',display:{xs:"none",md:'inline'}}}>Crypto</Typography>
                  <Box component='img' marginRight={1} marginLeft={1} height={{xs:20,md:25}} src={LogoImg} /> 
                  <Typography  fontSize={10} fontWeight={'700'}>BETA</Typography>
                </Typography>
              </Typography>
            </Link>
            {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton> */}
          </Box>

          <Box sx={{ ml: 'auto', width: 'max-content', display: 'flex' }}>
           
              <Button sx={{ mr: { xs: -2, sm: 1 } }}>
                <Link to='/dashboard/notification'>
                  <Badge sx={{
                    "& .MuiBadge-badge": {
                      color: "#fff",
                      backgroundColor: "red"
                    }
                  }} badgeContent={unread_notification?unread_notification:null}>
                    <Notifications sx={{ color: "#fff" }} />
                  </Badge>
                
                </Link>
              </Button>
           

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar sx={{ borderRadius: '100%', bgcolor: p_and_l < 0 ? "red" : "green", }}>
                  {user?.name?.charAt(0)}
                  {user?.name?.charAt(1)}
                </Avatar>
              </IconButton>
              <Menu
                id="menu-appbar"
                sx={{
                  borderRadius: 5,
                  padding: 2,
                  background: 'primary.dark',
                  "& .MuiPaper-root": {
                    backgroundColor: "primary.main",
                    width: { xs: '100%', sm: '400px' }
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 100,
                    height: 10,
                    backgroundColor: 'primary.main',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },

                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Button onClick={handleClose} sx={{ml:'auto',display:"block"}}>
                  <Close sx={{color:'#fff'}}/>
                </Button>
                <Box sx={{height:5,borderBottom:'1px solid grey'}}>
                </Box>
                <Box onClick={() => { navigate('/dashboard/my-account'); handleClose() }}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 1,
                    borderBottom: '1px solid grey',
                    width: '100%',
                    paddingBottom: 2,
                    padding: '10px 25px',
                  }}>
                  <Box>
                    <Avatar style={{ height: 60, width: 60, borderRadius: '100%', }}>
                      {user?.name?.charAt(0)}
                      {user?.name?.charAt(1)}
                    </Avatar>
                  </Box>
                  <Box sx={{ marginLeft: '10px' }}>
                    <Typography sx={{ color: '#fff' }} variant="h6">{user?.name} </Typography>
                    <Typography sx={{ color: '#fff' }}>Current Plan: {plan?.description||'Expired'}</Typography>
                  </Box>
                  <Box sx={{ marginLeft: 'auto' }}>
                    <ArrowForwardIos sx={{ color: '#fff' }} />
                  </Box>
                </Box>
                <Typography sx={{ padding: '10px 25px',color:"#fff",display: {xs:'none',md:'flex'}}}>UPGRADE</Typography>
                <Box sx={{ maxWidth: '450px', width: '100%', display: {xs:'none',md:'flex'}, justifyContent: 'space-evenly', bgcolor: 'primary.main', padding: '20px 10px',pt:0, borderBottom: '1px solid grey' }}>
                  {plans.map((data)=>(<PlanButton id={data._id} name={`${data.description} Plan`} pricing={`$${data.amount}/mo`} active={plan?.name==data?.name} />))}
                </Box>
                <MenuItem sx={{ color: '#fff', marginLeft: '10px' }} onClick={() => { navigate('/dashboard/main'); handleClose() }}>Home</MenuItem>
                <MenuItem sx={{ color: '#fff', marginLeft: '10px' }} onClick={() => { navigate('/dashboard/activity'); handleClose() }}>Activity</MenuItem>
                <Link to='https://xgain-store.creator-spring.com/' target='_blank'>
                <MenuItem sx={{ color: '#fff', marginLeft: '10px' }}>Merch</MenuItem>
                </Link>
               
                <MenuItem sx={{ color: '#fff', marginLeft: '10px' }} onClick={() => { handleClose() }}>
                 {anchorEl?<ExportCSV/>:null} 
                </MenuItem>
                <MenuItem sx={{ color: '#fff', marginLeft: '10px' }}
                >
                  <a href='mailto:support@xgain.ai' onClick={handleClose} style={{ textDecoration: 'none', color: '#fff' }}> Report Issue</a>
                </MenuItem>
                <MenuItem sx={{ color: '#fff', marginLeft: '10px' }} onClick={()=>setShowHelp(true)}>Help</MenuItem>
                <MenuItem sx={{ color: '#fff', marginLeft: '10px' }} onClick={handleLogout}>Log out</MenuItem>

              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box> */}
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
