import { Grid, Button,Box } from "@mui/material"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CategoryModal from "component/Modal/CategoryModal";
import { Add, Check } from "@mui/icons-material";
import AddCrypto from "component/Modal/AddNewCrypto";
import { useSelector } from "react-redux";
const BottomTab = () => {
    const [active,setActive]=useState('portfolio');
    const [visibleCategory,setVisibleCategory]=useState(false);
    const [showCoin,setShowCoin]=useState(false);
    const { plan } = useSelector(state => state.user);

    useEffect(()=>{
        let array=window.location.toString().split('/');
        let lastText=array[array.length-1];
        // if(!lastText || lastText=='main'){
        //     return;
        // }else 
        if(lastText=='assets'){
           return setActive('assets')
        }
        else if(lastText=='all-coin'){
           return setActive('all-coin')
        }
        else{
            return setActive('category')
        }
    },[])
    const toggleVisible=()=>{setVisibleCategory(prev=>!prev)}
    const toggleCoinVisible=()=>{setShowCoin(prev=>!prev)}
    return (
        <>
        <CategoryModal visible={visibleCategory} setVisble={setVisibleCategory}/>
        <AddCrypto visible={showCoin} setVisble={setShowCoin}/>
        <Box  sx={{fontSize:{xs:10},position: 'fixed', bottom: 25, left: '50%', transform: 'translateX(-50%)', display: {xs:'flex' }, border: '1px solid #fff', borderRadius: 5, overflow: 'hidden',maxWidth:'99vw', width: 'max-content',  margin: 'auto',zIndex:99 }} >
            {/* <Grid col={4} item>
                <Link to='/dashboard/main'>
                    <Button startIcon={active=='portfolio'&&<Check/>} sx={{bgcolor:active=='portfolio'&&'primary.light', width: '100%', height: 40, borderRadius: 0, borderRight: '1px solid #fff', }} variant="contained">Portfolio</Button>
                </Link>
            </Grid> */}
            <Grid col={4} item>
                {
                    plan?.name=='Pro'? 
                    <Button  endIcon={<ArrowDropUpIcon/>} onClick={toggleVisible} 
                    sx={{bgcolor:active=='category'&&'#293143', width: '100%', height: 40, borderRadius: 0,fontSize:{xs:10,sm:12,md:14} }} variant="contained">Portfolio</Button>
                    :
                    
                    <Link to='/dashboard/main'>
                    <Button 
                   
                    // sx={{bgcolor:active=='assets'&&'primary.light', width: '100%', height: 40, borderRadius: 0, borderLeft: '1px solid #fff' }} variant="contained">
                    sx={{bgcolor:active=='category'&&'#293143', width: '100%', height: 40, borderRadius: 0, borderLeft: '1px solid #fff',fontSize:{xs:10,sm:12,md:14} }} variant="contained">
                        Main
                    </Button>
                </Link>
                }
                   
            </Grid>

            <Grid col={4} item>
                <Link to='/dashboard/all-coin'>
                    <Button 
                   
                    // sx={{bgcolor:active=='assets'&&'primary.light', width: '100%', height: 40, borderRadius: 0, borderLeft: '1px solid #fff' }} variant="contained">
                    sx={{bgcolor:active=='all-coin'&&'#293143', width: '100%', height: 40, borderRadius: 0, borderLeft: '1px solid #fff',fontSize:{xs:10,sm:12,md:14}}} variant="contained">
                        All Coins
                    </Button>
                </Link>
            </Grid>

            <Grid col={4} item>
                <Link to='/dashboard/assets'>
                    <Button 
                    sx={{bgcolor:active=='assets'&&'#293143', width: '100%', height: 40, borderRadius: 0, borderLeft: '1px solid #fff',fontSize:{xs:10,sm:12,md:14} }} variant="contained">
                        Assets
                    </Button>
                </Link>
            </Grid>
            <Grid col={4} item>
                <Box >
                    <Button 
                    onClick={()=>{toggleCoinVisible();setActive('add-coint')}}
                    startIcon={<Add sx={{marginRight:-1}}/>}
                    // sx={{bgcolor:active=='add-coin'&&'#293143', width: '100%', height: 40, borderRadius: 0, borderLeft: '1px solid #fff',fontSize:{xs:10,sm:12,md:14} }} variant="contained"> 
                    sx={{bgcolor:active=='assets'&&'#293143', width: '100%', height: 40, borderRadius: 0, borderLeft: '1px solid #fff',fontSize:{xs:10,sm:12,md:14} }} variant="contained">
                    Add
                    </Button>
                </Box>
            </Grid>
        </Box>
        </>
    )
}

export default BottomTab;