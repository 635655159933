import React, { useState } from 'react';
import { TextField,InputAdornment,MenuItem } from '@mui/material';
import styled from "@emotion/styled";
// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import MenuItem from '@material-ui/core/MenuItem';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fff',
  },
 '& .MuiSelect-select':{
   color:"#fff"
  },
 '& .MuiSelect-select':{color:"#fff"},
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'grey',
    },
    '&:hover fieldset': {
      borderColor: 'grey',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'grey',
      boxShadow:'0px 0px 3px #fff'
    },
    '.MuiSvgIcon-root ': {
      fill: "white !important",
    }
  },
});

function PhoneNumberInputDark({data,setData,handleSubmit,id}) {
  
  const [countryCode, setCountryCode] = useState(data?.country_code||'+1');

  const handleCountryCodeChange = (event) => {
    let country_code=event.target.value ;
    let phone=data.phone;
    if(phone){
     setCountryCode(country_code);
     setData(country_code+" "+phone)
    } else{
      setCountryCode(country_code)
    }
   };

  const handlePhoneChange=(event)=>{
    setData(countryCode+" "+ event.target.value)
  }


  return (
      <CssTextField

      id={id}
      label='Phone'
      fullWidth
      type='number'
      required={true}
      placeholder={'Phone'}
      sx={{ my: "5px" }}
      onChange={handlePhoneChange}
      inputProps={{ style: { fontSize: 14,color:"#fff" } }} // font size of input text
      InputLabelProps={{ style: { fontSize: 14,color:'#fff'} }} // font size of input label
      
      defaultValue={data.phone}
      name='phone-login'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CssTextField
                select
                id='phone-input'
                value={countryCode}
                onChange={handleCountryCodeChange}
                variant="standard"
                sx={{color:'#fff !important'}}
                style={{color:'#fff !important'}}
                color='secondary'

                inputProps={{ style: { fontSize: 14,color:"#fff" } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 14,color:'#fff'} }} // font size of input label
                
              >
                <MenuItem value="+1">US +1</MenuItem>
                <MenuItem value="+971">UAE +971</MenuItem>
                <MenuItem value="+44">UK +44</MenuItem>
                <MenuItem value="+86">CH +86</MenuItem>
                <MenuItem value="+91">IN +91</MenuItem>
                <MenuItem value="+55">BR +55</MenuItem>
                <MenuItem value="+81">JA +81</MenuItem>
                <MenuItem value="+90">TR +90</MenuItem>
                <MenuItem value="+48">PO +48</MenuItem>
                <MenuItem value="+420">CZ +420</MenuItem>
                <MenuItem value="+351">PT +351</MenuItem>
                <MenuItem value="+34">SP +34</MenuItem>
              </CssTextField>
            </InputAdornment>
          ),
        }}
      />
  
  );
}

export default PhoneNumberInputDark;