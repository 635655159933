import { AttachMoney } from "@mui/icons-material";
import { Box, Button, InputAdornment } from "@mui/material";
import CustomInputDark from "component/Input/CustomInputDark";
import { useEffect, useState } from "react";

const SellPriceInput=({price=[],setPrice})=>{
 const [values,setValues]=useState(price);

 useEffect(()=>{
    if(!price.length){
        setValues(['','','',''])
    }else{
        setValues(price)
    }
 },[price])


 const handleChange=(value,index)=>{
    let temp=values;
    temp[index]=value;
    setValues([...temp])    
 }

 const handleSave=()=>{
    setPrice(values)
    setTimeout(()=>{
        let btn = document.querySelector('#save-sell');
        if (btn) {
            btn.click();
        }
    },500)
 }

return(
    <Box sx={{width:150,mx:'auto',marginTop:2}}>
        {values.map((value, index) => 
        <CustomInputDark
            startAdornment={
            <InputAdornment position="start">
              <AttachMoney sx={{color:"#ffff"}}/>
            </InputAdornment>
            }
            label={"Exit "+(index+1)}
            value={value}
            textFontSize={18}
            labelFontSize={15}
            onChange={(e) => handleChange(e.target.value, index)}
        />)}
        <Button
            sx={{
                cursor: 'pointer',
                color:'#fff',
                marginLeft: 'auto',
                marginBottom: 0.5,
                marginTop:1,
                padding: '5px 15px',
                width:'100%',
                border: '1px solid grey', borderRadius: 10
            }}
            onClick={handleSave}
        >
            Set
        </Button>
    </Box>
)
}

export default SellPriceInput;