import axios from "axios"

const category_mapper = {
    bluechip: 'Goldv2',
    bigcap: 'BigCap',
    defi: 'De-Fi',
    metaverse: 'Metaverse',  // Change it to MetaVerse
    nft: 'NFT',
    lowcap: 'LowCap',
}

export const addCrypto = (data) => {
    return axios.post('/api/v1/crypto', data)
}
export const addMoreCrypto = (id,data) => {
    return axios.post(`/api/v1/crypto/${id}/add-more-coin`, data)
}

export const getPortfolio = (portfolioname = 'main') => {
    return axios.get('/api/v1/crypto/portfolio/' + portfolioname)
}

export const getCategoty = (portfolioname = 'main', category = 'bluechip') => {
    return axios.get(`/api/v1/crypto/${portfolioname}/${category}`)
}

export const getCryptoById=(id)=>{
    return axios.get('/api/v1/crypto/'+id)

}

export const updateCrypto=(id,data)=>{
    return axios.patch(`/api/v1/crypto/${id}`,data)
}

export const deleteCrypto=(id)=>{
    return axios.delete(`/api/v1/crypto/${id}`)
}

export const getCryptoDetail=(portfolio='main',symbol)=>{
    return axios.get(`/api/v1/crypto/detail/${portfolio}/${symbol}`)
}

export const updateSell=(data,id)=>{
    return axios.post(`/api/v1/crypto/update-sell/${id}`,data)
}
export const getATH_ATL=(symbol)=>{
    return axios.post(`/api/v1/crypto/get-ath-atl`,{symbol})
}
export const getCategory=(symbol)=>{
    return axios.get(`/api/v1/crypto/my-category/${symbol}`)
}

export const mycoin=()=>{
    return axios.get(`/api/v1/crypto/my-coin`)  
}

export const updateWallet=(id,wallets)=>{
    return axios.put(`/api/v1/crypto/wallet/${id}`,{wallet:JSON.stringify(wallets)})
}
export const  getWallet=(id)=>{
    return axios.get(`/api/v1/crypto/exchange-wallet/${id}`)
}

export const getAllCategory=()=>{
    return  axios.get('/api/v1/category/');
}

export const updateCategory=(payload)=>{
    return  axios.patch('/api/v1/category/',payload);
}

export const requestUpdate=()=>{
    return axios.get(`/api/v1/crypto/request-update`)  
}