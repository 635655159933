import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { set_user } from "redux/reducer/user";
import Navbar from '../component/Navbar';
import Footer from "../component/Footer";

export const PageLayout = () => {
    const loaded = useSelector(state => state.user.loaded);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {pathname}=useLocation();

    useEffect(()=>{
        window.scrollTo(0,0)
    },[pathname])

    return (
        <Box sx={{padding:0,margin:0}}>
            <Navbar />
            <Box sx={{ padding: 0, }}>
                {/* {
                    !loaded ? <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress sx={{color:"common.white"}} />
                    </Box> : <Outlet />
                } */}
                <Outlet />
            </Box>
            <Footer />
        </Box>
    )
}

export default PageLayout;