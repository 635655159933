import { createTheme,colors } from "@mui/material";
const theme=createTheme({
    palette:{
        common:{
            red:colors.red[800],
           
           
        },
        primary:{
            main:"#21232E",
            light:"#293143",
            dark:"#000",
            white:"#fff"
        },
        secondary:{
            main:"#fff",
            light:"#fff",
            dark:"#fff",
            green:"#7B9476",
            red:"#B4343B",
        }
    }
})

export default theme;