import axios from "axios"

export const register=(data)=>{
 return axios.post("/api/v1/auth/register",data)
} 

export const verify=(type,data)=>{
    return axios.post("/api/v1/auth/verify/"+type.toLowerCase(),data)
}

export const sendCode=(username,captcha)=>{
    return axios.post("/api/v1/auth/send-code",{username,captcha})
}
export const resendCode=(username)=>{
    return axios.post("/api/v1/auth/resend-code",{username})
}
export const verifyCode=(username,code)=>{
    return axios.post("/api/v1/auth/verify-code",{username,code})
}
export const verifyCodeAndUpdate=(code,type)=>{
    return axios.post("/api/v1/user/verify-update",{code,type})
}


export const deleteMyAccount=()=>{
    return axios.delete('/api/v1/user/delete-my-account')
}