import React, { useState } from 'react';
import { TextField,InputAdornment,MenuItem } from '@mui/material';
// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import MenuItem from '@material-ui/core/MenuItem';


function PhoneNumberInput({name,error,helperText,value,data,onChange,onBlur,formik}) {
  
  const [countryCode, setCountryCode] = useState(data?.country_code||'+1');
  const handleCountryCodeChange = (event) => {
    formik.setFieldValue('country_code', event.target.value);
    setCountryCode(event.target.value);  
   };
  return (
      <TextField
      name={name}
      label='Phone'
      fullWidth
      type='number'
      required={true}
      placeholder={'Phone'}
      sx={{ my: "5px" }}
      value={value}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      inputProps={{ style: { fontSize: 14 } }} // font size of input text
    
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <TextField
                select
                value={countryCode}
                onChange={handleCountryCodeChange}
                variant="standard"
              >
                <MenuItem value="+1">US +1</MenuItem>
                <MenuItem value="+971">UAE +971</MenuItem>
                <MenuItem value="+44">UK +44</MenuItem>
                <MenuItem value="+86">CH +86</MenuItem>
                <MenuItem value="+91">IN +91</MenuItem>
                <MenuItem value="+55">BR +55</MenuItem>
                <MenuItem value="+81">JA +81</MenuItem>
                <MenuItem value="+90">TR +90</MenuItem>
                <MenuItem value="+48">PO +48</MenuItem>
                <MenuItem value="+420">CZ +420</MenuItem>
                <MenuItem value="+351">PT +351</MenuItem>
                <MenuItem value="+34">SP +34</MenuItem>
              </TextField>
            </InputAdornment>
          ),
        }}
      />
  
  );
}

export default PhoneNumberInput;