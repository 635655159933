import { useCallback, useEffect, useState } from "react"
import axios from "axios"
import LineChart from "component/Chart/LineChart"
import { Box, Button,Card } from "@mui/material"
import { useSelector } from "react-redux"
const PortfolioTracker=()=>{
    const [data,setData]=useState([]);
    const {crypto} = useSelector(state=>state.crypto)
    const [type,setType]=useState('day');
    useEffect(()=>{
        axios.get(`/api/v1/portfolio/portfolio-record?type=${type}`)
        .then(res=>setData(res.data||[]))
        .catch(()=>{})
    },[type,crypto]);

    const getActiveColor=useCallback((currentType)=>{
        if(currentType==type){
            return '#95F205'
        }
        return '#fff'
    },[type])

    return(
        <Box sx={{marginY:1,height:'100%'}}>
         <Card elevation={0} sx={{ bgcolor: 'primary.light', minHeight: {xs:250,sm:300}, padding: { xs: 1, md: 1 }, boxShadow: '0px 0px 2px #fff', width: '100%', height: '100%' }}>
          <Box sx={{ml:'auto',width:'max-content'}}>
            <Button sx={{color:"#fff",mx:0.1,p:0,w:1,color:getActiveColor('hr')}} onClick={()=>setType('hr')}>1H</Button>
            <Button sx={{color:"#fff",mx:0.1,p:0,w:1,color:getActiveColor('day')}} onClick={()=>setType('day')}>1D</Button>
            <Button sx={{color:"#fff",mx:0.1,p:0,w:1,color:getActiveColor('week')}} onClick={()=>setType('week')}>1W</Button>
            <Button sx={{color:"#fff",mx:0.1,p:0,w:1,color:getActiveColor('month')}} onClick={()=>setType('month')}>1M</Button>
            <Button sx={{color:"#fff",mx:0.1,p:0,w:1,color:getActiveColor('year')}} onClick={()=>setType('year')}>1Y</Button>
            <Button sx={{color:"#fff",mx:0.1,p:0,w:1,color:getActiveColor('all')}} onClick={()=>setType('all')}>All</Button>
          </Box>
          <LineChart  chartData={data}/>
          </Card>
        </Box>
        
    )
}
export default PortfolioTracker;