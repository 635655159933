import { BorderColor, CheckBoxOutlined, CheckBoxRounded, TaskAlt } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { getAllCategory, getCategory, getPortfolio, updateCategory } from 'api/crypto';
import CustomInputDark from 'component/Input/CustomInputDarkText';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {Link} from 'react-router-dom'
import { set_category,set_crypto } from 'redux/reducer/crypto';
import { set_message } from 'redux/reducer/message';
import FormatToIntCurrency from 'utils/FormatToIntCurrency';
import FormateCurrency from 'utils/FormateCurrency';
const CategoryModalItem = ({_id,name,link,gain,total_value,percent,onClick,editing=false,disableEditing,setEditedRecord}) => {
    const [categoryName,setCategoryName]=useState(name)
    const dispatch=useDispatch()
    const handleChange=(event)=>{
        setCategoryName(event.target.value)
    }

    useEffect(()=>{
        if(name!=categoryName){
            setEditedRecord(prev=>prev[name]?prev:{...prev,[name]:1})
        }else{
            setEditedRecord(prev=>{
                if(prev[name]){
                    delete prev[name]
                    return {...prev}
                }
                return prev
            })
        }
    },[name,categoryName])

    const handleSave=()=>{
        updateCategory({_id,name:categoryName})
        .then(()=>{
            dispatch(set_message("category updated !"))
            getAllCategory()
            .then((res)=>dispatch(set_category(res.data.category)))
            .catch(err=>console.log(err))
           getPortfolio('main')
           .then(res=>{
            dispatch(set_crypto(res.data.data[0].assets))
           }).catch(console.log)

        }).catch((e)=>{
            if(e?.response?.data?.msg){
                dispatch(set_message(e?.response?.data?.msg))
            }
        }).finally(()=>{
            disableEditing();
            setEditedRecord(prev=>{
                if(prev[name]){
                    delete prev[name]
                    return {...prev}
                }
                return prev
            })
        })
    }
    return (
    <Link onClick={onClick} style={{textDecoration:'none',color:'#fff'}} to={editing?undefined:link}>
    <Grid container sx={{
        bgcolor: 'primary.light',
        marginBottom: 0.7,
        padding: 1,
        alignItems: 'center',   
    }}>

{/* <Avatar sx={{ borderRadius: '100%', bgcolor: p_and_l < 0 ? "red" : "green", }}></Avatar> */}

        <Grid item xs={2}>
            <Box sx={{
                bgcolor: '#21232D',
                borderWidth: '1px',
                BorderColor: '#fff',
                height: 45,
                width: 45,
                justifyContent: 'center',
                alignItems: "center",
                display: 'flex',
                borderRadius: '100%',
                // bgcolor: p_and_l < 0 ? "red" : "green"
            }}>
                <Typography fontSize={14} fontWeight='bold'>{isFinite(percent)?percent:0}%</Typography>
            </Box>
        </Grid>
        {editing?
        <>
        <Grid item xs={8}>
        <CustomInputDark type={'text'} inputProps={{maxLength:10}}  name={'name'} sx={{height:'100%'}} value={categoryName} onChange={handleChange}/>
        </Grid>
        {name!=categoryName?
        <Grid item xs={2}>
        <Button onClick={handleSave}>
            <TaskAlt sx={{fontSize:30,color:"#fff"}}/>
        </Button>
        </Grid>
        :null}
        </>
        :
        <>
        <Grid sx={{
            display: 'flex',
            justifyContent: 'center'
        }}
            item xs={3}>
            <Typography textAlign='center' fontWeight='bold'>{name}</Typography>
        </Grid>
        <Grid item xs={3}>
            <Typography fontSize={17} textAlign='center' fontWeight='800'>{isNaN(gain)?0:gain?.toFixed(1)}<span style={{ color: 'red' }}>X</span> </Typography>
        </Grid>
        <Grid item xs={4}>
            <Typography fontWeight={'bold'} textAlign='center' >{FormatToIntCurrency(total_value)}</Typography>
        </Grid>
        </>}
    </Grid>
    </Link>
)}

export default CategoryModalItem;