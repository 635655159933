import '../gauge-chart.css';
import { Card, Typography ,Box} from '@mui/material';
import FormateCurrency from 'utils/FormateCurrency';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material';
import FormatToIntCurrency from 'utils/FormatToIntCurrency';
import LogoImg from 'assets/xgainTM.png'
import XLogo from 'assets/X.svg';
const GaugeChartAssets=({
    total_invested,
    total_roi,
    total_worth,
    profit_loss,
    xgain,
    label='',
    symbol,
})=>{
    const theme=useTheme()
    const isMobile=useMediaQuery(theme.breakpoints.down('xl'))
    return(
        <Card  elevation={0} sx={{bgcolor:'primary.light',boxShadow:'0px 0px 2px #fff',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',padding:2,height:'100%'}}>
       
        <Box sx={{background:profit_loss<0?'#e51616':'#52B12C'}} className="gauge">
          <div style={{top:xgain>=100?-15:xgain==0?0:'auto',transform:`rotate(${xgain>100?180:1.8*parseFloat(xgain)}deg)`}} className='needle-parent'>
          <div class="needle" ></div>
          </div>
          <Box className="gauge-center">
          </Box>
          <div class='info-area'>
             <Typography sx={{
                textAlign:'center',
                fontWeight:'bold',
                marginTop:'-8%',
                fontSize:{xs:'2.5rem',sm:'1.2rem',md:'1.9rem',lg:'2.8rem',xl:'3.5rem'},
                }}>
              <Box sx={{height:{md:40,lg:60,xl:80},display:'inline-block',m:0,p:0}} component={'img'} src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/${(symbol)?.toLowerCase()}.webp`}/>
              <Typography  sx={{color:'#fff',fontWeight:'bold',fontSize:18,marginTop:-1.5}} textTransform='capitalize'>{label}</Typography>
              </Typography>
              <Typography
               sx={{
               textAlign:'center',
               fontSize:{xs:'4rem',sm:'2rem',md:'2.8rem',lg:'4rem',xl:'5.8rem'},
               fontWeight:'bold',
               position:'absolute',
               bottom:-15,
               width:'100%',
            //    textShadow:{md:'2px 2px 3px #fff'}
            }}
              >{xgain}
              <Box component={'img'} sx={{height:{xs:'3rem',sm:'1.4rem',md:'2rem',lg:'2.6rem',xl:'3.8rem'}}} src={XLogo}/>
              </Typography>
          </div>
        </Box>
        <Box sx={{
            width:'100%',
            px:{xs:1,sm:2},
            display:'flex',
            justifyContent:'space-around',
            paddingTop:{xs:1,sm:2},
            color:'#fff',
            fontWeight:'bold'
        }}>
            <Box sx={{textAlign:'center'}}>
               <Typography sx={{fontWeight:'bold',fontSize:{xs:12,sm:14,md:14,lg:16,xl:16}}}>Invested</Typography>  
               <Typography sx={{fontSize:{xs:12,sm:14,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(total_invested):FormatToIntCurrency(total_invested)}</Typography>  
            </Box>
            <Box sx={{textAlign:'center'}}>
               <Typography sx={{fontWeight:'bold',fontSize:{xs:12,sm:14,md:14,lg:16,xl:16}}}>Total Worth</Typography>  
               <Typography sx={{fontSize:{xs:12,sm:14,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(total_worth):FormatToIntCurrency(total_worth)}</Typography>  
            </Box>
            <Box sx={{textAlign:'center'}}>
               <Typography sx={{fontWeight:'bold',fontSize:{xs:12,sm:14,md:14,lg:16,xl:16}}}>P&L</Typography>  
               <Typography sx={{color:profit_loss<0?'red':'#95F205',fontSize:{xs:12,sm:14,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(profit_loss):FormatToIntCurrency(profit_loss)}</Typography>  
            </Box>
            <Box sx={{textAlign:'center'}}>
               <Typography  sx={{fontWeight:'bold',fontSize:{xs:12,sm:14,md:14,lg:16,xl:16}}}>pROI</Typography>
               <Typography sx={{fontSize:{xs:12,sm:14,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(total_roi):FormatToIntCurrency(total_roi)}</Typography>
            </Box>
        </Box>
        </Card>
    )
}

export default GaugeChartAssets;