import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CategoryModalItem from './CategoryItem';
import { getPortfolio } from 'api/crypto';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 410,
    width:'100%',
    margin:'auto',
    bgcolor: 'primary.main',
    border: '2px solid #000',
    boxShadow: 24,
    color: 'common.white',
    p: 1.5,
    borderRadius: 3,
    marginBottom: 50,
};





export default function CategoryModal({visible,setVisble}) {
    const [open, setOpen] = React.useState(visible);
    const [crypto,setCrypto]=React.useState([]);
    const [extra,setExtra]=React.useState({editing:false});
    const category=useSelector(state=>state.crypto.category);
    const [editedRecord,setEditedRecord]=React.useState({});
    const categoryLinks=[
        {
            name:'Portfolio',
            title:'All',
            link:'/dashboard/main'
        },
        ...category.map(({name,_id})=>({
            name:name,
            title:name,
            link:'/dashboard/main/'+name,
            _id:_id

        }))
    ]

    const dataCollection = React.useMemo(() => {
        let temp = {}
        temp.allData = { data: crypto };
        temp.allData.total_invested = crypto.map(obj => obj.invested).reduce((a, b) => a + b, 0)
        temp.allData.grand_total_sell = crypto.map((obj) => obj.grand_total_sell).reduce((a, b) => a + b, 0);
        
        temp.Portfolio = { data: crypto };
        temp.Portfolio.total_invested = temp.Portfolio.data.map(obj => obj.invested).reduce((a, b) => a + b, 0)
        temp.Portfolio.grand_total_sell = temp.Portfolio.data.map((obj) => obj.grand_total_sell).reduce((a, b) => a + b, 0);
        for(let {name,_id} of category){
           temp[name] = { data: crypto.filter((obj => obj.categoryId == _id)) }
           temp[name].total_invested = temp[name].data.map(obj => obj.invested).reduce((a, b) => a + b, 0)
           temp[name].total_value = temp[name].data.map(obj => obj.price*obj.qty).reduce((a, b) => a + b, 0)
           temp[name].grand_total_sell = temp[name].data.map(obj => obj.grand_total_sell).reduce((a, b) => a + b, 0)
        }

        return categoryLinks.map(({name,title,link,_id})=>({
            link,
            gain:(temp[name]?.grand_total_sell/temp[name]?.total_invested),
            total_value:temp[name]?.grand_total_sell,
            percent:((temp[name]?.total_invested/temp?.allData.total_invested)*100).toFixed(0),
            name:title,
            _id:_id
        }))
        
    }, [crypto,category])

    React.useEffect(()=>{
        getPortfolio('main')
        .then(res=>setCrypto(res.data.data[0].assets))
        .catch((err)=>{
            if(err?.response?.status==401){
                window.location.href='/login'
            }
        })
    },[])
    React.useEffect(()=>{
        setOpen(visible);
    },[visible]);

    const handleClose = () => {
        if(extra.editing)return;
        setOpen(false);
        setVisble(false);
    }

    const toggleEditing = React.useCallback(() => {
        if (Object.keys(editedRecord).length > 1) return
        setExtra(prev => ({ ...prev, editing: !prev.editing }))
    }, [editedRecord,setExtra])
    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
                <Box sx={{marginBottom:1,marginTop:2,textAlign:'center'}}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                      Select Category
                    </Typography>
                </Box>
                <Button sx={{marginLeft:'auto',display:'block',color:'#98C9F6',my:1,p:1,textTransform:'capitalize',fontSize:14}} onClick={toggleEditing}>{extra.editing?"Cancel":"Edit"}</Button>
                <Box>
                    {dataCollection.map((item,index)=> <CategoryModalItem setEditedRecord={setEditedRecord} disableEditing={toggleEditing} editing={item.name=='All'?false:extra.editing} {...item} key={index} onClick={handleClose} {...item} />)}
                </Box>
            </Box>
        </Modal>
    );
}