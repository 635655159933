import { Button,CircularProgress } from "@mui/material";
const CustomButton = ({ sx, text, onClick, variant,type,loading, ...props }) => {
  return (
    <Button
      onClick={onClick}
      fullWidth
      type={type}
      disabled={loading}
      sx={{
        my: 1,
        mx: "auto",
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2,
        color: "#fff",
        background: "#000",
        ...sx,
      }}
      variant="contained"
    >
    {loading?<CircularProgress size={20} sx={{color:'#fff'}}/>:text}  
    </Button>
  );
};

export default CustomButton;
