import { Box,Button } from "@mui/material";
import ExchangeIcon from "./ExcangeIcon";
import AddWallet from "component/Modal/AddWallet/AddWallet";
import axios from "axios";
import { useState } from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSelector } from "react-redux";

const Exchanges=({data,symbol,size=20})=>{
    const [showWallet,setShowWallet]=useState(false);
    const [wallet,setWallet]=useState(data);
    const user=useSelector(state=>state.user?.user);
    if(data?.includes('Coinbase')){
        data = data.filter(item => item !== "Coinbase");
        data.unshift("Coinbase");
    }
    
    const toggleWallet=()=>{
        setShowWallet(!showWallet);
    }

    const setWalletApi=(exchanges)=>{
        axios.put(`/api/v1/admin/crypto/exchange`,{exchanges:JSON.stringify(exchanges),symbol})
        .then((res)=>{
            setWallet(exchanges)
            toggleWallet();
        })
        .catch(err=>console.log(err))
    }
return (
    <Box sx={{display:'flex'}}>
        {
            showWallet && <AddWallet
              toggleWallet={toggleWallet}
              wallet={wallet||[]}
              symbol={symbol}
              setWallet={setWalletApi}
            />
        }
        {wallet?.sort()?.map((name)=><ExchangeIcon size={size} name={name}/>)}
        {user?.is_admin &&
        <Button sx={{ minWidth: 0, fontSize: 20 }}  onClick={toggleWallet}>
            <AddCircleOutlineIcon sx={{color:"#fff"}} />
        </Button>}
    </Box>
)
}

export default Exchanges;