import React, { useState, useEffect } from 'react';
import bearMarket from 'assets/4yrBear.svg';
import accumulationYear from 'assets/4yrAccu.svg';
import btcHalving from 'assets/4yrBtc.svg';
import bullMarket from 'assets/4yr_Bull.svg';
import './four-year-cycle.css';
import {Box, Card, Typography } from '@mui/material';

const SingleBox=({text,img,year})=>{
    return(
        <Card  elevation={0} 
        sx={{
        bgcolor:'primary.light',borderRight:'0.1px solid #fff',
        width:'100%',padding:2,py:2,alignItems:'center',display:'flex',position:'relative',
        minHeight:100,
        flexGrow:1
        }}>
            <Typography sx={{position:'absolute',top:5,right:15,color:'#fff',zIndex:1,fontSize:{xs:15,md:25, lg:30}}}>{year}</Typography>
            <Box component={'img'} 
              sx={{
                position:{xs:'absolute',md:'static'},
                zIndex:{xs:0},
                opacity:{xs:0.4,md:1},
                margin:{xs:'auto',md:0},
                left:0,
                right:0
            }} src={img}
            />
            <Typography zIndex={1} sx={{maxWidth:'100%',fontSize:{xs:13,md:15,lg:24}, m:{xs:'auto',md:5},display:{xs:'block',md:'inline'},color:'#fff',wordWrap:'break-word',fontWeight:'bold',textAlign:{xs:'center',md:'inherit'}}}>{text}</Typography>
        </Card>
    )
}
const cardData=[
    {
        text:'BEAR MARKET',
        img:bearMarket,
        year:'2022'
    },
    {
        text:'ACCUMULATION',
        img:accumulationYear,
        year:'2023'
    },
    {
        text:'BTC HALVING',
        img:btcHalving,
        year:'2024'
    },
    {
        text:'BULL MARKET',
        img:bullMarket,
        year:'2025'
    }
];
function FourYearCycle() {
    const [date, setDate] = useState(new Date());
    const [pulse, setPulse] = useState(false);

    useEffect(() => {
        // Update the date every day
        const dayInterval = setInterval(() => {
            setDate(new Date());
        }, 86400000); // milliseconds for one day

        // Pulse effect
        const pulseInterval = setInterval(() => {
            setPulse(prev => !prev);
        }, 1000);

        return () => {
            clearInterval(dayInterval);
            clearInterval(pulseInterval);
        };
    }, []);

    // Calculate the current needle's position
    const timeDifferenceCurrent = date - new Date(2022, 0, 1);
    const totalTimeSpan = new Date(2025, 11, 31) - new Date(2022, 0, 1);
    const needlePosition = (timeDifferenceCurrent / totalTimeSpan) * 100;

    // Calculate the position for the red needle (19 April 2024, 02:01)
    const specialDate = new Date(2024, 3, 19, 2, 1);
    const timeDifferenceSpecial = specialDate - new Date(2022, 0, 1);
    const specialNeedlePosition = (timeDifferenceSpecial / totalTimeSpan) * 100;

    return (
       <Box sx={{my:1,bgcolor:'primary.light',boxShadow:'0px 0px 2px #fff',display:'flex',flexDirection:'row',position:'relative',height:'max-content',minHeight:100}}>
       {cardData.map((data)=> <SingleBox {...data}/>)}
        <div className="needle-4year" style={{ left: `${needlePosition}%`,}}></div>
        <div className="date-label" style={{ left: `calc(${needlePosition}% - 50px)` }}>
                {date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', })}
        </div>
        <div
                className="red-needle"
                style={{ left: `${specialNeedlePosition}%`, animation: pulse ? 'pulse 1s' : 'none' }}
            ></div>
        <div className="date-label " style={{top:8, left: `calc(${specialNeedlePosition}% - 50px)`,color:"red" }}>
                {specialDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short',  })}
        </div>
 
       </Box>
    );

    return (
        <div className="gauge-container">

            {/* <div className="image-container" style={{ backgroundImage: `url(${bearMarket})` }}>
                <div className="year-label">2022</div>
            </div>

            <div className="image-container" style={{ backgroundImage: `url(${accumulationYear})` }}>
                <div className="year-label">2023</div>
            </div>

            <div className="image-container" style={{ backgroundImage: `url(${btcHalving})` }}>
                <div className="year-label">2024</div>
            </div>

            <div className="image-container" style={{ backgroundImage: `url(${bullMarket})` }}>
                <div className="year-label">2025</div>
            </div>

            <div className="needle" style={{ left: `${needlePosition}%` }}></div>
            
            <div
                className="red-needle"
                style={{ left: `${specialNeedlePosition}%`, animation: pulse ? 'pulse 1s' : 'none' }}
            ></div>

            <div className="date-label" style={{ left: `calc(${needlePosition}% - 50px)` }}>
                {date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}
            </div> */}

        </div>
    );
}

export default FourYearCycle;
