import { Snackbar,Alert } from "@mui/material"
const SnackBarFibs=({data})=>{

if((data.ATH && data.ATL) && (data.ATH>data.ATL) ){
    return null;
}


return(
    <>
    <Snackbar
     anchorOrigin={{ vertical:'top', horizontal:'right' }}
     open={true}
     > 
     <Alert  severity="error">
     {(!data?.ATL|| !data.ATH) && (`${data.name||data.symbol} is missing ` + (!data.ATL ? "ATL ":'') +  (!data.ATH ?"ATH ":'')) }
     {(data?.ATL&&data.ATH) && data.ATL>data.ATH ? `${data.name} ATL Value is bigger then ATH. We are notified and will fix asap`:null }
     {(data.ATH) && (data?.ATL>=data?.ATH) ?', ATH/ATL Values Not seem right':null}
      , Please Click Edit button to fix.
     </Alert>
    </Snackbar>      
    </>
)
}

export default SnackBarFibs;