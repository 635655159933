import { TextField,InputAdornment,IconButton } from "@mui/material";
import { Visibility,VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
const CustomInput = ({ label,color,error,focused,placeHolder,name, value,type,onChange,onBlur,optional,autoComplete,helperText, ...props }) => {
  const [showPassword,setShowPassword]=useState(false);

  const handleClickShowPassword=()=>{
    setShowPassword(prev=>!prev)
  }
  return (
    <TextField
      helperText={helperText}
      color={color}
      required={optional?false:true}
      label={label}
      focused={focused}
      fullWidth
      placeholder={placeHolder}
      value={value}
      name={name}
      type={type=='password'?(!showPassword&&'password'):type}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      sx={{ my: "5px",}}
      InputProps={{ sx: { height: 45, fontSize: 18 }, 
      endAdornment:type=='password'?
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>:null
    }}
      inputProps={{ style: { fontSize: 18, } }} // font size of input text
      InputLabelProps={{ style: { fontSize: 18,} }} // font size of input label
      autoComplete={autoComplete}
     
    />
  );
};

export default CustomInput;
